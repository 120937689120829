<template>
    <div class="row justify-content-center">
        <base-alert
            type="warning"
            class="row justify-content-between align-items-center mx-3"
            style="width: 100%;"
            v-if="!user?.fullname || !user?.email || !user?.phone_number || !user?.address"
        >
            <div class="text-center">
                Please fill out your personal information in order to make a payout.
            </div>
            <base-button
                size="sm"
                class="mr-3"
                type="primary"
                fill
                @click="editProfile()"
                >Edit Personal Information</base-button
            >
        </base-alert>
        <div class="col-12 col-md-7" >
            <div class="row">
                <div class="stats-card py-0 col-md-12">
                    <card type="chart">
                        <template slot="header">
                            <h4 class="card-category" style="font-size: 14px;">Earnings since last invoice</h4>
                            <h4 class="card-title">
                                <i class="tim-icons icon-send text-success"></i>
                                <clip-loader
                                    v-if="loading"
                                    color="#C153EC"
                                    size="20px"
                                    :loading="loading"
                                    style="display: inline"
                                ></clip-loader>
                                <span v-else style="font-size: 22px;">
                                    {{  parseFloat(month_earning).toFixed(2) }} $
                                </span>
                            </h4>
                        </template>
                    </card>
                </div>
                <div class="stats-card py-2 col-md-12">
                    <card type="chart">
                        <template slot="header">
                            <h4 class="card-category" style="font-size: 14px;">Your next payment will be on</h4>
                            <h4 class="card-title">
                                <i class="tim-icons icon-send text-success"></i>
                                <clip-loader
                                    v-if="loading"
                                    color="#C153EC"
                                    size="20px"
                                    :loading="loading"
                                    style="display: inline"
                                ></clip-loader>
                                <span v-else style="font-size: 22px;">
                                    {{  nextPayment }}
                                </span>
                            </h4>
                        </template>
                    </card>
                </div>
            </div>
            <div class="card">
                <div class="card-header">
                    <div class="">
                        <h4 class="mb-3 card-title text-bold">Select Payout Method</h4>
                    </div>
                </div>
                <div class="row px-3">
                    <div class="col-6 col-md-3" style="background: transparent;  " v-for="payment in payments">
                        <div class="payment-card" 
                        :style="{
                            background: siteTheme == 'black' ? '#26293C' : 'white', 
                            opacity: payment.disabled ? 0.8 : 1,
                            cursor: payment.disabled ? 'default' : 'pointer'
                        }" 
                            :class="{'selected-card': payment?._id == selectedPayment?._id }"
                        >
                            <i class="my-2 mt-3" :class="payment.icon"></i>
                            <div class="payment-card-body px-1">
                                <span class="text-bold " :style="{color: siteTheme === 'black' ? 'white' : 'undefined'}" >{{  payment.title }}</span>
                                <!-- <p >{{ payment.description }}</p> -->
                            </div>
                            <base-button size="sm" :type="'primary'" :disabled="payment.disabled" @click="selectPayment(payment)" fill>
                                <span v-if="payment.disabled">Coming soon</span>
                                <span v-else>Select</span>
                            </base-button>

                        </div>

                    </div>
                </div>
                <div v-if="selectedPayment">
                    <div class="col-12" v-for="(input, index) in selectedPayment.inputs">
                        <base-input v-if="input.type == 'text'" source="offer" :label="input.label" :placeholder="input.label" v-model="model[input.label]" > </base-input>
                        <base-select 
                            source="offer"
                            v-if="input.type == 'select'" 
                            :label="input.label"
                            class="w-full" 
                            :options="selectedPaymentInputs[index]?.options" 
                            :onChange="(val) => onFilterChange(input.label, val)"
                            :default="model[input.label]"

                        >
                        </base-select>
                    </div>
                </div>

                <div class="col-12" v-if="selectedPayment && selectedPayment.immediate">
                    <small style="font-size: 12px; font-weight: 600;">*Minimum payment threshold for this method is <span style="color: #00DA48;">{{ selectedPayment.threshold || 0 }}$</span></small>
                </div>
                <div class="col-12" v-if="selectedPayment && Array.isArray(selectedPayment.notes)">
                    <small v-for="note in selectedPayment.notes" style="font-size: 12px; font-weight: 600;"><span v-html="note"></span></small>
                </div>

                
                <div class="card-footer" v-if="selectedPayment">
                    <h5 class="text-danger text-bold" style="font-size: 1em" v-if="errors.savePayment">{{ errors?.savePayment }}</h5>
                    <base-button size="sm" :type="'primary'" @click="savePaymentMethod()" fill>Save</base-button>
                </div>

            </div>

        </div>
        <div class="col-md-5 col-12">
            <div class="card pb-3">
                <div class="card-header">
                    <div class="">
                        <h4 class="mb-3 card-title text-bold text-center">General information</h4>
                        <h5>Full Name: {{ user?.fullname }}</h5>
                        <h5>Email: {{ user?.email }}</h5>
                        <h5>Phone Number: {{ user?.phone_number }}</h5>
                        <h5>Address: {{ user?.address }}</h5>
                    </div>
                    <div class="row justify-content-center">
                        <base-button size="sm" :type="'primary'" @click="editProfile()" fill>Edit</base-button>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-12">
            <card class="card" :title="table1.title">
                <div class="table-responsive">
                    <payment-history-table
                        :currentPage="currentPage"
                        :perPage="perPage"
                        :data="table1.data" 
                        :columns="table1.columns"
                        thead-classes="text-primary"
                    >
                    </payment-history-table>

                </div>
                <div>
                    <div
                        v-if="historyLoading"
                        style="height: 40px; width: 100%"
                        class="row justify-content-center align-items-center"
                    >
                        <clip-loader color="#00DA48" size="40px" :loading="historyLoading"></clip-loader>
                    </div>
                    <div v-if="table1.data.length === 0 && !historyLoading" class="row justify-content-center">
                        <span>No results found</span>
                    </div>
                </div>
            </card>
        </div>

    </div>
</template>
<script>
import { mapGetters } from "vuex";
import { BaseSelect, BaseAlert } from "@/components";
import moment from 'moment';
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import {PaymentHistoryTable} from "@/components"

const tableColumns = [ "id", "Method", "Created at", "Covering", "Amount","Status"];

export default {
    components: {
        BaseSelect,
        BaseAlert,
        ClipLoader,
        PaymentHistoryTable

    },
    data() {
        return {
            siteTheme: localStorage.getItem('theme'),
            selectedPayment: null,
            model: {},
            loading: false,
            historyLoading: true,
            selectedPaymentInputs: [],
            onChangeCounter: 1,
            currentPage: 1,
            perPage: 50,
            table1: {
                title: "Payment History",
                columns: [...tableColumns],
                data: []
            },
        };
    },
    async mounted() {
        if (!this.user) {
            await this.$store.dispatch("getUser");
        }
        this.getPaymentHistory()
        await this.getPayments();
        const selectedPayment = this.payments.find(x => this.user.payment_info?.method == x._id);
        if (selectedPayment) {
            this.selectedPayment = selectedPayment;
            this.mapPaymentInputOptions()
            this.model = this.user.payment_info.info
            // console.log('---this.model', this.model["Network"]);
            // this.onFilterChange("Network", this.model.Network)
            
        }
    },
    computed: {
        ...mapGetters(["payments", "user", "errors", "month_earning", "paymentHistory"]),
        computedInputs() {
            const arr = this.selectedPayment.inputs.map(x => {
                if (x.options?.length > 0) {
                    x = {
                        ...x,
                        options: x.options.map(o => ({title: o, value: o}))
                    }
                }

                return {...x};
            })

            // console.log('===-----arr', arr);
            return arr
        },
        nextPayment() {
            return moment().add(1, "month").startOf('month').format('dddd, Do MMMM')

        }
    },
    methods: {
        async getPayments() {
            this.loading = true;
            await this.$store.dispatch("getPayments");
            this.loading = false;
        },
        async getPaymentHistory() {
            this.historyLoading = true;
            await this.$store.dispatch("getPaymentHistory");
            this.table1.data = [...this.paymentHistory]
            this.historyLoading = false;
        },
        editProfile() {
            this.$router.push('/profile')
        },
        selectPayment(payment) {
            if (payment.disabled) return;
            this.selectedPayment = payment
            this.model = {}
            this.mapPaymentInputOptions()
        },
        mapPaymentInputOptions() {
            const arr = this.selectedPayment.inputs.map(x => {
                if (x.options?.length > 0) {
                    x = {
                        ...x,
                        options: x.options.map(o => ({title: o, value: o}))
                    }
                }
                this.model = {
                    ...this.model,
                    [x.label]: null
                }
                return {...x};
            })
            
            this.selectedPaymentInputs = arr;
        },
        onFilterChange(label, value) {
            // if (this.onChangeCounter % 2 === 0) {
                // console.log('--onFIlterChange', label, value, this.onChangeCounter % 2);            
                // console.log('---inside');
                this.model = {
                    ...this.model,
                    [label]: value
                }
                // console.log('--this.model', this.model, this.onChangeCounter);
            // }

            // this.onChangeCounter++
        },
        async savePaymentMethod() {
            this.$store.commit("setErrors", { savePayment: null });

            // console.log('--savePaymentMethod', this.model);
            // validate inputs
            const isValid = this.validatePaymentInputs();
            // console.log('---isValid', isValid);
            if (!isValid) {
                this.$store.commit("setErrors", { savePayment: "Some required fields are missed" });
                return;
            }

            this.loading = true
            const result = await this.$store.dispatch("savePaymentMethod", {payment: this.selectedPayment._id, inputs: this.model})
            if (result) {
                this.notifyVue(true, "Payment method successfully saved!")
            }
            this.loading = false

        },
        validatePaymentInputs() {
            const payment = this.selectedPayment;
            const inputs = this.model;
            for (let i = 0; i < payment.inputs.length; i++) {
                const paymentInput = payment.inputs[i];
                // console.log('---paymentInput', paymentInput);
                if (paymentInput.required) {
                    const input = inputs[paymentInput.label];
                    // console.log('--input', input, inputs, );
                    if (!input) {
                        return false;
                    }
                }
            }

            return true;
        },
        notifyVue(success, message) {
            this.$notify({
                message: message,
                icon: "tim-icons icon-bell-55",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: success ? "success" : "danger",
                timeout: 5000,
            });
        },
    },
    watch: {},
};
</script>
<style>
.payment-card {
    /* background-color: white;  */
    padding-bottom: 10px;
    text-align: center;
    border-radius: 5px;
    margin-bottom: 30px;
    border: solid;
    border-width: 0.2px;
    border-color: #ccc;
    cursor: pointer;
}

.selected-card {
    border-color: #00DA49;
    border-width: 1px;
    color: #00DA49;
}

.payment-card i {
    font-size: 40px;
}

.payment-card-body {
    padding-top: 10px;
    padding-bottom: 10px;
}
</style>

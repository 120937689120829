<!-- eslint-disable vue/no-mutating-props -->
<template>
    <div class="row">
        <modal :show="show" id="searchModal" :centered="false" :show-close="true">
            <div class="row px-md-2" :style="{ 'background-color': theme == 'black' ? '#28293D' : 'white' }">
                <h4 style="font-weight: bold; padding-left: 15px">Choose {{ model.email }} as winner?</h4>
                <base-alert type="warning" dismissible>
                    <span>Please remember to replace '[Insert Reward Name]' and '[Insert Contact Email]' with the specific details of your reward and contact information before sending.</span>
                </base-alert>
                <div class="col-md-12">
                    <base-text-area
                        rows="5"
                        label="Email Content"
                        type="textarea"
                        placeholder="Name"
                        v-model="model.text"
                    >
                    </base-text-area>
                </div>

                <div class="px-3 mt-4">
                    <base-button
                        class="mr-3"
                        :class="{ 'button-loading': loading }"
                        :disabled="!model.text"
                        @click="save"
                        slot="footer"
                        type="primary"
                        fill
                    >
                        <clip-loader v-if="loading" color="white" size="25px" :loading="loading"></clip-loader>
                        <span v-else="">Confirm</span>
                    </base-button>

                    <base-button :disabled="loading" @click="close" slot="footer" type="danger" fill
                        >Cancel</base-button
                    >
                </div>
            </div>
        </modal>
    </div>
</template>
<script>
import Modal from "@/components/Modal";

import { BaseAlert } from "@/components";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import { BaseSelect } from "@/components";
import { BaseTextArea } from "@/components";

export default {
    components: {
        BaseAlert,
        Modal,
        ClipLoader,
        BaseSelect,
        BaseTextArea,
    },
    data() {
        return {
            theme: localStorage.getItem("theme"),
            typeOptions: [
                { title: "Withdraw all funds", value: "all" },
                { title: "Choose from options", value: "multiple" },
            ],
        };
    },
    props: {
        model: {
            type: Object,
            default: () => {
                return {};
            },
        },
        show: {
            type: Boolean,
            default: false,
        },
        save: {
            type: Function,
        },
        close: {
            type: Function,
        },
        loading: Boolean,
        onSelectChange: Function,
        contest: String,
    },
};
</script>
<style></style>

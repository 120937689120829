<template>
    <div class="row">
        <div class="col-12">
            <card :title="table1.title">
                <base-alert
                    v-for="endedContest in endedContests"
                    type="default"
                    class="row justify-content-between align-items-center mx-2"
                >
                    <div class="text-center">
                        Hey your contest '{{ endedContest.title }}' is over, would you like to pick a random winner?
                    </div>
                    <base-button
                        size="sm"
                        @click="openPickWinnerModal(endedContest._id)"
                        class="mr-3"
                        type="primary"
                        fill
                        >Pick Random Winner</base-button
                    >
                </base-alert>
                <div class="row justify-content-between px-4 align-items-center">
                    <div class="col-md-3" style="padding-left: 0px">
                        <base-select
                            :source="'offer'"
                            :onChange="(val) => onFilterChange('contest', val)"
                            :value="contest"
                            :label="contestFilter.label"
                            class="pr-2 w-full"
                            :options="contestFilter.options"
                            :default="contest"
                        ></base-select>
                    </div>
                    <div class="row col-7 col-md-6 justify-content-end">
                        <base-input
                            :onChange="(val) => onSearchChange(val)"
                            :model="search"
                            :placeholder="'Search ...'"
                            class="mr-2 searchFilter"
                            :value="search"
                        ></base-input>
                    </div>
                </div>

                <pick-winner
                    :show="showPickWinnerModal"
                    :close="closeAddRewardModel"
                    :model="pickWinnerModel"
                    :loading="loads?.createReward"
                    :onSelectChange="onSelectRewardType"
                    :contest="selectedEndedContest"
                    :save="() => setWinner(selectedParticipantWinner, selectedEndedContest)"
                >
                </pick-winner>
                <div class="table-responsive">
                    <reward-table
                        :currentPage="currentPage"
                        :perPage="perPage"
                        :data="table1.data"
                        :columns="table1.columns"
                        thead-classes="text-primary"
                        :onSelect="onSelect"
                        :selectedItems="selectedItems"
                        :loadingItem="loadingItem"
                        :loading="loading"
                        :pickWinner="openPickWinnerModal"
                        :showDetails="openUserDetails"
                    >
                    </reward-table>
                    <div v-if="table1.data.length >= 1 && !loading">
                        <pagination
                            :totalItems="table1.data.length"
                            :totalPages="totalPages"
                            :currentPage="currentPage"
                            :onSelect="selectPage"
                            :perPage="perPage"
                            :onPerPageChange="onPerPageChange"
                        ></pagination>
                    </div>

                    <div
                        v-else-if="loading || contestLoading"
                        style="height: 40px; width: 100%"
                        class="row justify-content-center align-items-center"
                    >
                        <clip-loader color="#C153EC" size="40px" :loading="loading || contestLoading"></clip-loader>
                    </div>
                    <div v-else class="row justify-content-center white text-white">
                        <span>No results found</span>
                    </div>
                </div>
            </card>
        </div>
    </div>
</template>
<script>
import { RewardTable } from "@/components";
import { BaseSelect, BaseButton, BaseAlert } from "@/components";
import { Pagination } from "@/components";
import { mapGetters } from "vuex";
import Countries from "../assets/data/countries.json";
import PickWinner from "./Rewards/PickWinner.vue";

import ClipLoader from "vue-spinner/src/ClipLoader.vue";

const tableColumns = [
    {title: "participant id", key: "user_id"}, 
    {title: "name", key: "name"}, 
    {title: "email", key: "email"}, 
    {title: "contest", key: "contest.title"},
    {title: "Is a winner", key: "contest.winner"},
    {title: "Social shares", key: "total_social" },
    {title: "Payout", key: "total_payout" },
    {title: "Date joined", key: "createdAt"},
    {title: "control", key: "control"}
];

export default {
    components: {
        RewardTable,
        BaseSelect,
        Pagination,
        BaseButton,
        PickWinner,
        ClipLoader,
        BaseAlert,
    },
    async mounted() {
        await this.$store.dispatch("getUser");
        // this.getRewards();
        this.getContests();
    },
    data() {
        return {
            table1: {
                title: "Rewards",
                columns: [...tableColumns],
                data: [],
            },
            contestFilter: {
                label: "Contest",
                name: "contest",
                options: [{ title: "ALL", value: "all" }],
            },
            contest: "all",
            currentPage: 1,
            perPage: 10,
            selectedItems: [],
            showPickWinnerModal: false,
            pickWinnerModel: {
                id: null,
                email: null,
                text: "Congratulations!\nYou've been selected as the winner of the [Insert Reward Name] from the [Insert Contest Name].\nTo claim your prize, please reach out to us at [Insert Contact Email]",
            },
            contestLoading: false,
            loading: false,
            loadingItem: null,
            search: null,
            selectedEndedContest: null,
            selectedParticipantWinner: null,
        };
    },
    computed: {
        totalPages() {
            return Math.ceil(this.filteredParticipants.length / this.perPage);
        },
        isDark() {
            return localStorage.getItem("theme") === "black";
        },
        filteredParticipants() {
            if (this.search) {
                const value = this.search.toLowerCase().trim();
                let arr = this.contestParticipants.filter(
                    (x) => x.name?.toLowerCase()?.includes(value) || x.email?.toLowerCase().includes(value) || x.user_id?.toLowerCase().includes(value),
                );
                // console.log('---filtered contestParticipants', arr, this.contestParticipants);

                return arr;
            }

            return this.contestParticipants;
        },
        endedContests() {
            const contests = this.contests.filter((x) => {
                // console.log('-----x.winners', !x.winners, x.winners.length <= 0, );
                return (!x.winners || x.winners.length <= 0) && new Date().getTime() > new Date(x.date?.end).getTime();
            });
            // console.log('---endedContests', contests);
            return contests;
        },
        ...mapGetters(["contestParticipants", "loads", "user", "contests"]),
    },
    methods: {
        async getContests() {
            this.contestLoading = true;
            await this.$store.dispatch("getContests", { select: "title" });
            this.contestFilter.options = [
                { title: "ALL", value: "all" },
                ...this.contests.map((x) => ({ title: x.title.toUpperCase(), value: x._id })),
            ];
            this.contestLoading = false;
        },
        getContestParticipants: async function (withLoading = true) {
            this.loading = withLoading;
            await this.$store.dispatch("getContestParticipants", {
                contest: this.contest,
            });
            // console.log("---filteredParticipants", this.filteredParticipants);
            this.table1.data = [...this.filteredParticipants];
            // console.log('getOfferwalls', this.offerwall);
            // console.log("----this.contestFilter", this.contestFilter.options);
            // if (this.contest && this.contest !== "all") {
            //     const item = this.contestFilter.options.find((x) => x.value == this.contest);
            //     // console.log('----item', item);
            //     if (item) this.contest = item;
            // } else {
            //     this.contest = this.contestFilter.options[0]?.value;
            // }
            this.loading = false;
            this.currentPage = 1;
        },
        onSearchChange: function (val) {
            this.search = val;
        },
        selectPage: function (page) {
            if (page > this.totalPages || page < 1) return;
            this.currentPage = page;
        },
        onSelect: function (ids, value) {
            if (value) {
                this.selectedItems = [...new Set(this.selectedItems.concat(ids))];
            } else {
                this.selectedItems = [];
            }
        },
        openPickWinnerModal: function (contest, participant) {
            // console.log("---this.showPickWinnerModal", this.showPickWinnerModal);
            this.selectedEndedContest = contest;
            this.selectedParticipantWinner = participant;
            this.showPickWinnerModal = true;
        },
        closeAddRewardModel: function () {
            this.showPickWinnerModal = false;
            this.selectedEndedContest = null;
            this.selectedParticipantWinner = null;
        },
        notifyVue(success, message) {
            this.$notify({
                message: message,
                icon: "tim-icons icon-bell-55",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: success ? "success" : "danger",
                timeout: 5000,
            });
        },
        onPerPageChange: function (val) {
            // console.log("-onPerPageChange", val);
            if (!isNaN(val)) {
                this.perPage = val;
                this.currentPage = 1;
            }
        },
        onSelectRewardType: function (key, val) {
            // console.log("-onSelectRewardType", key, val);
            this.pickWinnerModel[key] = val;
        },
        onFilterChange: function (key, val) {
            this[key] = val;
            // console.log('----onFilterChange', key, val);
            // if (this.initialized) {
            this.getContestParticipants();
            // }
        },
        async setWinner(participant, contest) {
            this.loadingItem = { winner: participant };
            await this.$store.dispatch("setContestWinner", { contest, winner: participant, email: this.pickWinnerModel?.text });
            await this.getContests()
            await this.getContestParticipants(false);
            this.closeAddRewardModel();
            this.loadingItem = { winner: false };
        },
        async openUserDetails(id) {
            this.$router.push(`/users/${id}`);
        },
    },
    watch: {
        filteredParticipants(val) {
            this.table1.data = [...val];
        },
    },
};
</script>
<style></style>

<template>
    <div class="row">
        <div class="col-12">
            <delete-popup 
            :show="deletePopup"
            :close="toggleDeletePopup"
            :save="deleteContest"
            :contest="selectedContest"
            :loading="loadingItem?.delete === selectedContest?._id"
        ></delete-popup>
        <div class="">
            <card :title="table1.title">
                <div class="row justify-content-end px-4">
                    <div class="row col-10 justify-content-end">
                        <base-button @click="addContest" type="primary" fill>New Contest</base-button>
                    </div>
                </div>

                <div class="table-responsive">
                    <contest-table
                        :currentPage="currentPage"
                        :perPage="perPage"
                        :switchStatus="updateContest"
                        :edit="navigateToContest"
                        :remove="toggleDeletePopup"
                        :data="table1.data"
                        :columns="table1.columns"
                        thead-classes="text-primary"
                        :onSelect="onSelect"
                        :selectedItems="selectedItems"
                        :loadingItem="loadingItem"
                        :loading="loading"
                        :sortedColumns="table1.sortedColumns"
                    >
                    </contest-table>
                    <div v-if="table1.data.length >= 1 && !loading">
                        <pagination
                            :totalItems="table1.data.length"
                            :totalPages="totalPages"
                            :currentPage="currentPage"
                            :onSelect="selectPage"
                            :perPage="perPage"
                            :onPerPageChange="onPerPageChange"
                        ></pagination>
                    </div>

                    <div
                        v-else-if="loading"
                        style="height: 40px; width: 100%"
                        class="row justify-content-center align-items-center"
                    >
                        <clip-loader color="#C153EC" size="40px" :loading="loading"></clip-loader>
                    </div>
                    <div v-else class="row justify-content-center white text-white">
                        <span>No results found</span>
                    </div>
                </div>
            </card>
        </div>
        </div>

    </div>
</template>
<script>
import { ContestTable } from "@/components";
import { BaseSelect, BaseButton } from "@/components";
import { Pagination } from "@/components";
import { mapGetters } from "vuex";
import Countries from "../assets/data/countries.json";
import DeletePopup from "./Contests/DeletePopup.vue";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

const tableColumns = [
    {title: "Title", key: "title"},
    {title: "Status", key: "status", method: "getStatus"},
    {title: "Winners", key: "winners"},
    {title: "Social shares", key: "is_social_active"},
    {title: "Start", key: "date.start"},
    {title: "End", key: "date.end"},
    {title: "Control", key: "control"},
]

export default {
    components: {
        ContestTable,
        BaseSelect,
        Pagination,
        BaseButton,
        ClipLoader,
        DeletePopup
    },
    mounted() {
        // console.log("---contests", this.contests);
        // let countries = Countries.map((x) => {
        //     return {
        //         title: x.name,
        //         value: x.code,
        //     };
        // });
        // this.countryFilter.options = [...this.countryFilter.options, ...countries];
        this.getContests();
    },

    data() {
        return {
            table1: {
                title: "Contests",
                columns: [...tableColumns],
            sortedColumns: [...tableColumns],
                data: [],
            },
            currentPage: 1,
            perPage: 10,
            selectedItems: [],
            loading: false,
            loadingItem: null,
            selectedContest: null,
            deletePopup: false,

        };
    },
    computed: {
        totalPages() {
            return Math.ceil(this.contests.length / this.perPage);
        },
        isMajoritySelectedActive() {
            const item = this.contests.find((x) => x.id == this.selectedItems[0]);
            if (item && item.active) return true;
            return false;
        },
        ...mapGetters(["contests", "loads"]),
    },
    methods: {
        getContests: async function () {
            this.loading = true;
            await this.$store.dispatch("getContests");
            this.table1.data = [...this.contests];
            this.loading = false;
            this.currentPage = 1;
        },
        // addContest: async function () {
        //     console.log("----addContest", this.addContestModel);
        //     const { affiliate_id, name } = this.addContestModel;
        //     if (!affiliate_id || !name) {
        //         // show error message
        //         return;
        //     }
        //     this.loading = true;
        //     await this.$store.dispatch("createContest", this.addContestModel);
        //     await this.getContests();
        //     this.loading = false;
        //     this.notifyVue(true, "Successfully created");
        // },
        updateContest: async function (ids, status) {
            // console.log("----ids", ids, status);
            this.loadingItem = { active: ids[0] };
            await this.$store.dispatch("updateContest", { active: !status, ids });
            // await this.getContests()
            this.loadingItem = null;
            this.notifyVue(true, "Successfully updated");
        },
        toggleDeletePopup: function (contest) {
            if (contest && !this.deletePopup) {
                this.selectedContest = contest
                this.deletePopup = true
            } else {
                this.deletePopup = false
                this.selectedContest = null
            }
        },
        deleteContest: async function (id) {
            this.loadingItem = { delete: id };
            await this.$store.dispatch("deleteContest", id);
            // await this.getContests()
            this.loadingItem = null;
            this.toggleDeletePopup()
            this.notifyVue(true, "Successfully removed");
        },
        onFilterChange: function (key, val) {
            this[key] = val;
            this.getContests();
        },
        selectPage: function (page) {
            if (page > this.totalPages || page < 1) return;
            this.currentPage = page;
        },
        onSelect: function (ids, value) {
            if (value) {
                this.selectedItems = [...new Set(this.selectedItems.concat(ids))];
            } else {
                this.selectedItems = [];
            }
        },
        addContest: function () {
            // this.showAddContestModal = true;
            this.$router.push(`/contest/new`);
        },
        navigateToContest: function (id) {
            this.$router.replace(`/contest/${id}`);
        },
        notifyVue(success, message) {
            this.$notify({
                message: message,
                icon: "tim-icons icon-bell-55",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: success ? "success" : "danger",
                timeout: 5000,
            });
        },
        onPerPageChange: function (val) {
            // console.log("-onPerPageChange", val);
            if (!isNaN(val)) {
                this.perPage = val;
                this.currentPage = 1;
            }
        },
    },
    watch: {
        contests(val) {
            this.table1.data = [...val];
        },
    },
};
</script>
<style></style>

<!-- eslint-disable vue/no-mutating-props -->
<template>
    <div class="row">
        <modal :show="show" id="searchModal"  :centered="false" :show-close="true">
            <div class="" :style="{'background-color': theme == 'black' ? '#28293D' : 'white'}">
                <span class="title" style="font-size: 18px; color: #F23B46;">Delete {{ contest?.title }}</span>

                <div style="font-weight: 600;">
                    Are you sure you want to delete this contest? this action is irreversible
                </div>
            </div>

            <div
                    class="px-3 mt-4"
                >
                    <base-button class="mr-3" :class="{'button-loading': loading}" :disabled="loading" @click="save(contest._id)" slot="footer" type="danger" fill>
                        <clip-loader v-if="loading" color="white" size="25px" :loading="loading" ></clip-loader>
                        <span v-else="">Delete</span>
                    </base-button>

                    <base-button :disabled="loading" @click="close" slot="footer" type="secondary" fill>Cancel</base-button>

                </div>

        </modal>


    </div>
</template>
<script>
import Modal from '@/components/Modal';

import { BaseAlert } from '@/components';
import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

export default {
    components: {
        BaseAlert,
        Modal,
        ClipLoader
    },
    data() {
        return {
        theme: localStorage.getItem("theme")
        }
    },
    props: {
        model: {
            type: Object,
            default: () => {
                return {};
            }
        },
        show: {
            type: Boolean,
            default: false
        },
        save: {
            type: Function,
        },
        close: {
            type: Function
        },
        contest: Object,
        loading: Boolean
    }
};
</script>
<style>

</style>

<template>
    <div class="row">
        <div class="col-md-6">
            <edit-profile-form
                view="profile"
                :errors="errors"
                :onChange="onChange"
                :model="model"
                :success="success"
                :formLoading="formLoading"
                :updateUser="updateUser"
            >
            </edit-profile-form>
        </div>
        <div class="col-md-6">
            <edit-profile-form
                view="password"
                :errors="errors"
                :onChange="onChange"
                :model="model"
                :success="success"
                :formLoading="formLoading"
                :updateUser="updateUser"
            >
            </edit-profile-form>
        </div>
    </div>
</template>
<script>
import EditProfileForm from "./Profile/EditProfileForm";
import UserCard from "./Profile/UserCard";
import vueAuth from "../services/auth";
import { mapGetters } from "vuex";
import NotificationTemplate from "./Notifications/NotificationTemplate";
import { isValidPassword, validateInputs } from "../services/utils";

export default {
    components: {
        EditProfileForm,
        UserCard,
    },
    data() {
        return {
            model: {},
            loading: false,
            formLoading: false,
            success: false,
        };
    },
    computed: {
        ...mapGetters(["error", "user", "errors"]),
        errorMessage() {
            return this.errors.offerwall;
        },
    },
    updated() {
        // console.log('---user profile', this.user, this.model);
        if (this.model === null) this.model = this.user;
    },
    mounted() {
        // console.log('---vueauth', vueAuth?.getToken(), vueAuth?.isAuthenticated());
        this.getUser();
    },
    methods: {
        getUser: async function () {
            this.loading = true;
            const result = await this.$store.dispatch("getUser");
            this.model = result.data;
            this.loading = false;
            // console.log('---user profile', this.user, this.model, result);
        },
        updateUser: async function (source) {
            this.$store.commit("setErrors", { password: null, profile: null });
            let successMessage = "Profile Successfully Updated";
            const { username, fullname, phone_number, address, email, company, website, skipe, telegram, discord, _id, password, confirmPassword } =
                this.model;
            let payload = { _id, source };

            // console.log('---source', source);
            if (source == "password") {
                const validation = this.validatePasswordForm();
                // console.log('---validation', validation);
                if (validation !== true) {
                    this.$store.commit("setErrors", { password: validation });
                    return;
                }
                payload = { ...payload, password, confirmPassword };
                successMessage = "Password Successfully Updated";
            } else {
                const validationError = validateInputs(this.model, [
                    {key: 'username', title: "Username"},
                    {key: 'fullname', title: "Full Name"},
                    {key: 'email', title: "Email"},
                    {key: 'address', title: "Address"},
                ])
                if (validationError) {
                    this.$store.commit("setErrors", { profile: validationError });
                    return;
                }

                payload = { ...payload,phone_number, username, fullname, address, email, company, website, skipe, telegram, discord };
            }

            this.formLoading = true;
            this.success = false;
            const response = await this.$store.dispatch("updateUser", payload);
            this.formLoading = false;
            // this.success = true;
            if (response.success) this.notifyVue(true, successMessage);
        },
        notifyVue(success, message) {
            this.$notify({
                message,
                icon: "tim-icons icon-bell-55",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: success ? "success" : "danger",
                timeout: 5000,
            });
        },
        onChange(key, val) {
            if (key.includes("settings")) {
                const parts = key.split(".");
                this.model.settings = {
                    ...this.model.settings,
                    [`${parts[1]}`]: val,
                };
                return;
            }
            this.model[`${key}`] = val;
        },
        validatePasswordForm() {
            if (this.model.password) {
                if (this.model.password.length < 8) return `Password must contain at least 8 characters`;

                if (!isValidPassword(this.model.password)) {
                    return "Passowrd must contain at least 1 number and 1 capital letter"
                }

                if (this.model.password !== this.model.confirmPassword) return `Confirm Password does not match you password`;
            
                return true;
            }

            return `Password and Confirm Password are required`;
        },
    },
    watch: {
        // errors(val) {
        //   console.log('-----errors', val);
        //   // this.model = val
        // }
    },
};
</script>
<style></style>

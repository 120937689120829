<template>
    <div class="">
        <base-button
            class="mb-3 "
            @click="goBack"
            size="sm"
            :type="'secondary'"
            fill
        >
            <i class="fas fa-arrow-left mr-2"></i>
            Back
        </base-button>
        <div class="row">
            <div class="col-md-8 hide-mobile">
                <click-table> </click-table>
            </div>
            <div class="col-md-4">
                <user-card :user="visitor"></user-card>
            </div>
            <div class="col-md-8 show-mobile">
                <click-table> </click-table>
            </div>
            <div class="col-md-12">
                <conversion-table> </conversion-table>
            </div>
        </div>

        <!-- <div
      class="col-md-12"
    >
        <violation-table>

      </violation-table>
    </div> -->
    </div>
</template>
<script>
import UserCard from "./User/UserCard";
import ClickTable from "./User/ClickTable";
import ConversionTable from "./User/ConversionTable";
import ViolationTable from "./User/ViolationTable";
import { Preview } from "@/components";
import { mapGetters } from "vuex";
import { BaseButton } from "@/components";

export default {
    components: {
        ClickTable,
        ConversionTable,
        ViolationTable,
        UserCard,
        Preview,
        BaseButton
    },
    data() {
        return {
            loading: false,
            formLoading: false,
            success: false,
        };
    },
    computed: {
        ...mapGetters(["visitor", "errors", "successMessages"]),
        errorMessage() {
            return this.errors.user;
        },
    },
    updated() {
        // console.log('---user updated', this.errors.user);
        if (this.model === null) this.model = this.user;
    },
    mounted() {
        this.getUser();
    },
    methods: {
        getUser: async function () {
            this.loading = true;
            await this.$store.dispatch("getVisitor", this.$route.params.id);
            this.loading = false;
        },
        updateUser: async function () {
            this.formLoading = true;
            await this.$store.dispatch("updateUser", { ids: [this.$route.params.id], ...this.model });
            this.offerwallLinkRefreshor += 1;
            await this.getUser();
            this.formLoading = false;
            // console.log("----this.success.offerwall", this.successMessages);
            if (this.successMessages.offerwall) {
                this.notifyVue(true, this.successMessages.offerwall);
            }
        },
        goBack() {
            this.$router.back()
        },
        notifyVue(success, message) {
            this.$notify({
                message,
                icon: "tim-icons icon-bell-55",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: success ? "success" : "danger",
                timeout: 5000,
            });
        },
    },
    watch: {},
};
</script>
<style></style>

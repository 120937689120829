<template>
    <div class="row justify-content-center">
        <div class="col-12">
            <card :title="table1.title">
                <div class="row justify-content-start px-2">
                    <div class="col-12 justify-content-start align-items-center filters">
                        <!-- <base-select :source="'offer'" :onChange="(val) => onFilterChange('offerwall', val)" :value="offerwall"
              :label="offerwallFilter.label" class="pr-2  col-md-3 w-full" :options="offerwallFilter.options"
              :default="offerwall"></base-select> -->
                        <base-select
                            :source="'offer'"
                            :onChange="(val) => onFilterChange('groupBy', val)"
                            :value="groupBy"
                            :label="groupByFilter.label"
                            class="pr-2 col-md-2 w-full"
                            :options="groupByFilter.options"
                            :default="groupBy"
                        ></base-select>
                        <base-select
                            :source="'offer'"
                            :onChange="(val) => onFilterChange('country', val)"
                            :value="country"
                            :label="countryFilter.label"
                            class="pr-2 col-md-2 w-full"
                            :options="countryFilter.options"
                            :default="country"
                        ></base-select>

                        <base-input
                            :source="'offer'"
                            :label="'Offer ID'"
                            :onChange="(val) => onFilterChange('offer', val)"
                            :model="offer"
                            :placeholder="'Offer ID ...'"
                            class="mr-2 col-md-2 Filter"
                            :value="offer"
                        ></base-input>
                        <base-select
                            :source="'offer'"
                            :onChange="(val) => onFilterChange('contest', val)"
                            :value="contest"
                            :label="contestFilter.label"
                            class="mr-2 col-md-2 Filter"
                            :options="contestFilter.options"
                            :default="contest"
                        ></base-select>
                        <div class="row col-md-3 justify-content mt-3">
                            <base-button
                                @click="getGlobalStats"
                                size="sm"
                                :type="'primary'"
                                style="max-height: 38px; margin-top: 2px; margin-right: 10px"
                                fill
                                >Apply</base-button
                            >
                            <base-button
                                @click="clearFilters"
                                size="sm"
                                :type="'secondary'"
                                fill
                                style="max-height: 38px; margin-top: 2px"
                                >Clear filters</base-button
                            >
                        </div>
                    </div>
                </div>
                <div class="row justify-content-between align-items-end mt-4 px-4 mb-4">
                    <div style="display: flex; flex-direction: column" class="range-picker">
                        <slot name="label">
                            <label class="control-label bold"> Range </label>
                        </slot>
                        <date-range-picker
                            ref="picker"
                            v-model="range"
                            :locale-data="{ firstDay: 1, format: 'dd-mm-yyyy hh:MM' }"
                            @update="onFilterChange('range', $event)"
                            :time-picker="false"
                            :timePicker="false"
                            :timePicker24Hour="false"
                            :showWeekNumbers="false"
                            :showDropdowns="false"
                            :ranges="formatRanges()"
                        >
                            <template v-slot:input="picker" style="min-width: 350px; background: #26293c">
                                <i class="tim-icons icon-calendar-60" style="color: black; margin-right: 10px"></i>
                                <span style="color: black"
                                    >{{ formatDate(range.startDate) }} - {{ formatDate(range.endDate) }}</span
                                >
                            </template>
                        </date-range-picker>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <card type="chart">
                            <template slot="header">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <!-- <h5 class="card-category">{{$t('dashboard.totalShipments')}}</h5> -->
                                        <h2 class="card-title">Activities</h2>
                                    </div>
                                </div>
                            </template>
                            <div class="chart-area">
                                <div
                                    v-if="loadings.globalStats"
                                    style="height: 100%"
                                    class="row justify-content-center align-items-center"
                                >
                                    <clip-loader
                                        color="#C153EC"
                                        size="40px"
                                        :loading="loadings.globalStats"
                                    ></clip-loader>
                                </div>
                                <line-chart
                                    :style="chartStyle"
                                    ref="chart"
                                    chart-id="purple-line-chart"
                                    :chart-data="purpleLineChart.chartData"
                                    :gradient-colors="purpleLineChart.gradientColors"
                                    :gradient-stops="purpleLineChart.gradientStops"
                                    :extra-options="purpleLineChart.extraOptions"
                                >
                                </line-chart>
                            </div>
                        </card>
                    </div>
                </div>

                <!-- <div v-if="selectedItems.length > 0">
                    <base-button
                        @click="updateOffer(selectedItems, isMajoritySelectedActive)"
                        size="sm"
                        :type="isMajoritySelectedActive ? 'danger' : 'primary'"
                        fill
                        >{{ isMajoritySelectedActive ? "Disable All" : "Enable all" }}</base-button
                    >
                </div> -->

                <div class="table-responsive">
                    <stats-table
                        :loading="loadings.globalStats"
                        :perPage="perPage"
                        :data="table1.data"
                        :columns="table1.columns"
                        thead-classes="text-primary"
                        :loadingItem="loadingItem"
                        :currentPage="currentPage"
                        :label="tableLabel"
                        :groupBy="groupBy"
                    >
                    </stats-table>
                    <!-- <div v-if="table1.data.length >= 1 && !loadings.globalStats">
                        <pagination
                            :totalItems="table1.data.length"
                            :totalPages="totalPages"
                            :currentPage="currentPage"
                            :onSelect="selectPage"
                            :perPage="perPage"
                            :onPerPageChange="onPerPageChange"
                        ></pagination>
                    </div>

                    <div
                        v-else-if="loadings.globalStats"
                        style="height: 40px; width: 100%"
                        class="row justify-content-center align-items-center"
                    >
                        <clip-loader color="#C153EC" size="40px" :loading="loading.globalStats"></clip-loader>
                    </div> -->
                </div>
            </card>
        </div>
    </div>
</template>
<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { StatsTable } from "@/components";
import { BaseSelect } from "@/components";
import { BaseInput } from "@/components";
import { Pagination } from "@/components";
import { mapGetters } from "vuex";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import ViewOffer from "./Offer/ViewOffer.vue";
import Countries from "../assets/data/countries.json";
import moment from "moment";
import config from "@/config";
import * as chartConfigs from "@/components/Charts/config";
import LineChart from "@/components/Charts/LineChart";

const tableColumns = ["Label", "Clicks", "Conversions", "CVR", "EPC", "EPU", "Payout"];
const currentDate = new Date();

export default {
    components: {
        DateRangePicker,
        BaseSelect,
        BaseInput,
        Pagination,
        ClipLoader,
        ViewOffer,
        StatsTable,
        LineChart,
    },
    data() {
        return {
            range: {
                startDate: currentDate.toUTCString(),
                endDate: new Date(currentDate.setDate(currentDate.getDate() + 1)).toString(),
            },
            table1: {
                title: "Stats",
                columns: [...tableColumns],
                data: [],
            },
            countryFilter: {
                label: "Country",
                name: "country",
                options: [{ title: "ALL", value: "all" }],
            },
            groupByFilter: {
                label: "Group By",
                name: "groupBy",
                options: [
                    { title: "Hour", value: "hour" },
                    { title: "Day", value: "day" },
                    { title: "Offer", value: "offer" },
                    // { title: "Country", value: "country" },
                ],
            },
            contestFilter: {
                label: "Contest",
                name: "contest",
                options: [
                    { title: "All", value: "all" },
                ],
            },
            offerFilter: {
                label: "Offer",
                name: "offerwall",
                options: [],
            },
            contest: "all",
            country: "all",
            groupBy: "hour",
            tableLabel: "hour",
            offer: null,
            tool: null,
            currentPage: 1,
            perPage: 10,
            selectedItems: [],
            loadings: {},
            initialized: false,
            loadingItem: {},
            purpleLineChart: {
                extraOptions: chartConfigs.purpleChartOptions,
                activeIndex: 0,
                chartData: {
                    labels: ["JUL", "AUG", "SEP", "OCT", "NOV", "DEC"],
                    datasets: [
                        {
                            label: "Clicks",
                            fill: true,
                            borderColor: config.colors.primary,
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: config.colors.primary,
                            pointBorderColor: "rgba(255,255,255,0)",
                            pointHoverBackgroundColor: config.colors.primary,
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            pointRadius: 4,
                            data: [80, 100, 70, 80, 120, 80],
                        },
                        {
                            label: "Conversions",
                            fill: true,
                            borderColor: config.colors.danger,
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: config.colors.danger,
                            pointBorderColor: "rgba(201, 36, 36, 0)",
                            pointHoverBackgroundColor: config.colors.danger,
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            pointRadius: 4,
                            data: [50, 10, 40, 84, 20, 40],
                        },
                    ],
                },
                gradientColors: config.colors.primaryGradient,
                gradientStops: [1, 0.2, 0],
                activeIndex: 0,
            },
            // pinnedOffers: []
        };
    },
    async mounted() {
        console.log("--this.range", this.range);
        let countries = Countries.map((x) => {
            return {
                title: x.name,
                value: x.code,
            };
        });
        this.countryFilter.options = [...this.countryFilter.options, ...countries];
        await this.getContests()
        this.getGlobalStats();
    },
    computed: {
        totalPages() {
            return Math.ceil(this.filteredOffers.length / this.perPage);
        },
        filteredOffers() {
            // if(isNaN(parseInt(this.search))) {
            //   return this.offers.filter(x => x.offer_id.includes(this.search))
            // }
            if (this.search) {
                const value = this.search.toLowerCase().trim();
                let arr = this.offers.filter(
                    (x) => x.name?.toLowerCase()?.includes(value) || x.offer_id?.toLowerCase().includes(value),
                );
                // console.log('---filtered offers', arr, this.offers);

                return arr;
            }

            return this.offers;
        },
        isMajoritySelectedActive() {
            const item = this.filteredOffers.find((x) => x.id == this.selectedItems[0]);
            if (item && item.active) return true;
            return false;
        },
        chartStyle() {
            return {
                height: "100%",
                display: this.loadings.globalStats ? "none" : undefined,
            };
        },
        ...mapGetters(["globalStats", "contests"]),
    },

    methods: {
        onFilterChange(type, value) {
                        if (type === "range") {
                this.range = value;
                this.getGlobalStats();
                return;
            }

            this[type] = value;
        },
        clearFilters: function () {
            // console.log('---clear filters');
            this.offer = null;
            this.country = "all";
            this.groupBy = "hour";
            this.getGlobalStats();
        },
        getContests: async function () {
            await this.$store.dispatch("getContests");
            this.contestFilter.options = [...this.contestFilter.options, ...this.contests.map(x => ({title: x.title, value: x._id}))]
        },
        getGlobalStats: async function () {
            const filters = {
                range: { from: this.range.startDate, to: this.range.endDate },
                country: this.country,
                offer: this.offer,
                groupBy: this.groupBy,
                contest: this.contest
            };
            this.loadings = { globalStats: true };
            const result = await this.$store.dispatch("getGlobalStats", filters);
            // console.log("--result", result);
            this.loadings = { globalStats: false };
            this.formatDataTable(this.globalStats);
            this.tableLabel = filters.groupBy;
        },
        formatDate: function (date) {
            return moment(date).format("DD MMMM YYYY");
        },
        formatRanges: function () {
            const today = [moment().startOf("day").toDate(), moment().endOf("day").toDate()];
            const yesterday = [
                moment().subtract(1, "day").startOf("day").toDate(),
                moment().subtract(1, "day").endOf("day").toDate(),
            ];
            const last7days = [moment().subtract(7, "day").startOf("day").toDate(), moment().endOf("day").toDate()];
            const last15days = [moment().subtract(15, "day").startOf("day").toDate(), moment().endOf("day").toDate()];
            const month = [moment().startOf("month").toDate(), moment().endOf("month").toDate()];
            const lastMonth = [
                moment().subtract(1, "month").startOf("month").toDate(),
                moment().subtract(1, "month").endOf("month").toDate(),
            ];
            const year = [moment().startOf("year").toDate(), moment().endOf("year").toDate()];
            // console.log("---year", year);
            return {
                Today: today,
                Yesterday: yesterday,
                "Last 7 days": last7days,
                "Last 15 days": last15days,
                "This month": month,
                "Last month": lastMonth,
                "This year": year,
            };
        },
        formatDataTable: function ({ reports, stats }) {
            const chartData = {
                labels: stats.map((x) => {
                    // console.log("----", this.groupBy);
                    if (this.groupBy === "offer") return x.offer.offer_id;

                    return x[this.groupBy];
                }),
                datasets: [
                    {
                        label: "Clicks",
                        fill: true,
                        borderColor: config.colors.primary,
                        borderWidth: 2,
                        borderDash: [],
                        borderDashOffset: 0.0,
                        pointBackgroundColor: config.colors.primary,
                        pointBorderColor: "rgba(255,255,255,0)",
                        pointHoverBackgroundColor: config.colors.primary,
                        pointBorderWidth: 20,
                        pointHoverRadius: 4,
                        pointHoverBorderWidth: 15,
                        pointRadius: 4,
                        data: stats.map((x) => x.totalClicks),
                    },
                    {
                        label: "Conversions",
                        fill: true,
                        borderColor: config.colors.danger,
                        borderWidth: 2,
                        borderDash: [],
                        borderDashOffset: 0.0,
                        pointBackgroundColor: config.colors.danger,
                        pointBorderColor: "rgba(201, 36, 36, 0)",
                        pointHoverBackgroundColor: config.colors.danger,
                        pointBorderWidth: 20,
                        pointHoverRadius: 4,
                        pointHoverBorderWidth: 15,
                        pointRadius: 4,
                        data: stats.map((x) => x.totalConversions),
                    },
                ],
            };

            // console.log("---this.$refs", this.$refs.chart);
            this.$refs.chart.updateGradients(chartData);
            this.purpleLineChart.chartData = chartData;

            return (this.table1.data = stats);
            const arr = [];
            console.log("---data", data);
            for (const key in data) {
                const element = data[key];
                arr.push({
                    hour: key,
                    ...element,
                });
            }

            console.log("-arr", arr);

            this.table1.data = arr;
        },
    },
    watch: {},
};
</script>
<style></style>

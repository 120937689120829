<template>
    <div class="row justify-content-center">
        <div class="col-12">
            <card>
            <div class="col-12">
                <div class="my-4">
                    <h4 class="text-center">
                        Affiliates you have referred to us are shown here, you will earn 5% commission for every dollar
                        they make.
                    </h4>

                    <div class="row mt-4 align-items-center" v-if="editMode">
                        <base-input
                            :label="'Enter your referral alias'"
                            class="mr-2 col-md-8 col-sm-12 col-lg-5 input-with-icon"
                            :value="ref_alias"
                            v-model="ref_alias"
                            placeholder="Referral alias"
                        ></base-input>
                        <div class="row justify-content-center align-items-center mt-3">
                            <base-button
                                @click="saveRefAlias"
                                :type="'primary'"
                                class="mr-2"
                                fill
                                >
                                <clip-loader v-if="editLoading" color="white" size="25px" :loading="editLoading"></clip-loader>
                                <span v-else="">Save</span>
                                </base-button
                            >
                            <base-button
                                @click="switchEditMode"
                                :type="'secondary'"
                                fill
                                >
                                <span >Cancel</span>
                                </base-button
                            >
                        </div>
                    </div>

                    <h5 class="text-danger mb-4" style="font-size: 1em; font-weight: 600;" v-if="errors.profile">{{ errors.profile }}</h5>

                    <div class="row mt-4 align-items-center referral-link-block" v-if="!editMode">
                        <div style="width: 100%;" class="show-mobile">
                            <base-input
                                :label="'This is your personal referral link'"
                                class="mr-2 col-md-8 col-12 col-lg-5 input-with-icon"
                                :value="referralLink"
                                :model="referralLink"
                                disabled
                            ></base-input>
                        </div>
                        <base-input
                            :label="'This is your personal referral link'"
                            class="mr-2 col-md-8 col-12 col-lg-5 input-with-icon hide-mobile"
                            :value="referralLink"
                            :model="referralLink"
                            disabled
                        ></base-input>

                        <div style="position: relative; right: 0px" >
                            <i
                                style="position: absolute; right: 40px; cursor: pointer"
                                class="tim-icons icon-single-copy-04"
                                @click="copyToClipboard()"
                            ></i>
                        </div>

                        <div class="row justify-content-center align-items-center mt-3">
                            <base-button
                                @click="switchEditMode"
                                :type="'primary'"
                                fill
                                >Update referral alias</base-button
                            >
                        </div>
                    </div>
                    <div class="row mx-0 share-block" style="margin-top: 15px; align-items: center;">
                        <p class="mr-2" style="margin-top: 5px"><strong>Share on:</strong></p>
                        <!-- <i @click="copyToClipboard()" style="font-size: 28px; cursor: pointer; color: #5865F2;" class="mr-2 fab fa-discord"></i> -->
                        <i @click="shareLink('facebook')" style="font-size: 28px; cursor: pointer; color: #1877F2;" class="mr-2 fab fa-facebook-square"></i>
                        <i @click="shareLink('twitter')" style="font-size: 28px; cursor: pointer; color: #1DA1F2;" class="mr-2 fab fa-twitter-square"></i>
                        <i @click="shareLink('telegram')" style="font-size: 28px; cursor: pointer; color: #0088CC;" class="mr-2 fab fa-telegram"></i>
                        <i @click="shareLink('whatsapp')" style="font-size: 30px; cursor: pointer; color: #25D366;" class="mr-2 fab fa-whatsapp-square"></i>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 px-0">
                        <card type="chart">
                            <template slot="header">
                                <div class="row">
                                    <h3 style="margin-bottom: 5px">Earnings</h3>

                                    <div class="col-sm-6">
                                        <!-- <h5 class="card-category">{{$t('dashboard.totalShipments')}}</h5> -->
                                    </div>
                                    <div class="col-sm-12">
                                        <div
                                            class="btn-group btn-group-toggle"
                                            :class="'float-right'"
                                            data-toggle="buttons"
                                        >
                                            <label
                                                v-for="(option, index) in bigLineChartCategories"
                                                :key="option"
                                                class="btn btn-sm btn-primary btn-simple"
                                                :class="{ active: purpleLineChart.activeIndex === index }"
                                                :id="index"
                                            >
                                                <input
                                                    type="radio"
                                                    @click="getReferralReports(index)"
                                                    name="options"
                                                    autocomplete="off"
                                                    :checked="purpleLineChart.activeIndex === index"
                                                />
                                                {{ option }}
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </template>
                            <div class="chart-area">
                                <div
                                    v-if="loadings.globalStats"
                                    style="height: 100%"
                                    class="row justify-content-center align-items-center"
                                >
                                    <clip-loader
                                        color="#C153EC"
                                        size="40px"
                                        :loading="loadings.globalStats"
                                    ></clip-loader>
                                </div>
                                <line-chart
                                    :style="chartStyle"
                                    ref="chart"
                                    chart-id="purple-line-chart"
                                    :chart-data="purpleLineChart.chartData"
                                    :gradient-colors="purpleLineChart.gradientColors"
                                    :gradient-stops="purpleLineChart.gradientStops"
                                    :extra-options="purpleLineChart.extraOptions"
                                >
                                </line-chart>
                            </div>
                        </card>
                    </div>
                </div>
                <div>
                    <h3>{{ table1.title }}</h3>
                    <div class="table-responsive">
                        <referral-table
                            :perPage="perPage"
                            :data="table1.data"
                            :columns="table1.columns"
                            thead-classes="text-primary"
                            :currentPage="currentPage"
                        >
                        </referral-table>
                        <!-- <div v-if="table1.data.length >= 1 && !loadings.summary">
                        <pagination
                            :totalItems="table1.data.length"
                            :totalPages="totalPages"
                            :currentPage="currentPage"
                            :onSelect="selectPage"
                            :perPage="perPage"
                            :onPerPageChange="onPerPageChange"
                        ></pagination>
                    </div>

                    <div
                        v-else-if="loadings.summary"
                        style="height: 40px; width: 100%"
                        class="row justify-content-center align-items-center"
                    >
                        <clip-loader color="#C153EC" size="40px" :loading="loading.summary"></clip-loader>
                    </div> -->
                    </div>
                </div>
            </div>
        </card>
        </div>
    </div>
</template>
<script>
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { ReferralTable } from "@/components";
import { BaseSelect } from "@/components";
import { BaseInput } from "@/components";
import { Pagination } from "@/components";
import { mapGetters } from "vuex";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import ViewOffer from "./Offer/ViewOffer.vue";
import Countries from "../assets/data/countries.json";
import moment from "moment";
import config from "@/config";
import * as chartConfigs from "@/components/Charts/config";
import LineChart from "@/components/Charts/LineChart";
import { copyToClipboard } from "../services/utils";

const tableColumns = ["Username", "Register Date", "Amount"];

export default {
    components: {
        Pagination,
        ClipLoader,
        ViewOffer,
        ReferralTable,
        LineChart,
    },
    data() {
        return {
            table1: {
                title: "Referrals",
                columns: [...tableColumns],
                data: [],
            },
            currentPage: 1,
            perPage: 10,
            loadings: {},
            initialized: false,
            loadingItem: {},
            purpleLineChart: {
                extraOptions: chartConfigs.greenChartOptions,
                activeIndex: 0,
                chartData: {
                    labels: ["JUL", "AUG", "SEP", "OCT", "NOV", "DEC"],
                    datasets: [
                        {
                            label: "Conversions",
                            fill: true,
                            borderColor: config.colors.danger,
                            borderWidth: 2,
                            borderDash: [],
                            borderDashOffset: 0.0,
                            pointBackgroundColor: config.colors.danger,
                            pointBorderColor: "rgba(201, 36, 36, 0)",
                            pointHoverBackgroundColor: config.colors.danger,
                            pointBorderWidth: 20,
                            pointHoverRadius: 4,
                            pointHoverBorderWidth: 15,
                            pointRadius: 4,
                            data: [50, 10, 40, 84, 20, 40],
                        },
                    ],
                },
                gradientColors: config.colors.primaryGradient,
                gradientStops: [1, 0.2, 0],
                activeIndex: 0,
            },
            editMode: false,
            ref_alias: null,
            editLoading: false
            // pinnedOffers: []
        };
    },
    mounted() {
        this.getReferralReports(0);
        this.getReferralSummary();
        this.ref_alias = this.user.ref_alias
    },
    computed: {
        totalPages() {
            return Math.ceil(this.filteredOffers.length / this.perPage);
        },
        bigLineChartCategories() {
            return this.$t("referral.chartCategories");
        },
        filteredOffers() {
            // if(isNaN(parseInt(this.search))) {
            //   return this.offers.filter(x => x.offer_id.includes(this.search))
            // }
            if (this.search) {
                const value = this.search.toLowerCase().trim();
                let arr = this.offers.filter(
                    (x) => x.name?.toLowerCase()?.includes(value) || x.offer_id?.toLowerCase().includes(value),
                );
                // console.log('---filtered offers', arr, this.offers);

                return arr;
            }

            return this.offers;
        },
        isMajoritySelectedActive() {
            const item = this.filteredOffers.find((x) => x.id == this.selectedItems[0]);
            if (item && item.active) return true;
            return false;
        },
        chartStyle() {
            return {
                height: "100%",
                display: this.loadings.globalStats ? "none" : undefined,
            };
        },
        ...mapGetters(["referralReports", "referralSummary", "referralLink", "user", "errors"]),
    },

    methods: {
        shareLink(type) {
            if (type === "facebook") {
                window.open(`https://www.facebook.com/sharer/sharer.php?u=${this.referralLink}`, "_blank")
            }
            if (type === "twitter") {
                window.open(`https://twitter.com/intent/tweet?text=${this.referralLink}`, "_blank")
            }
            if (type === "whatsapp") {
                window.open(`https://api.whatsapp.com/send?text=${this.referralLink}`, "_blank")
            }
            if (type === "telegram") {
                window.open(`https://t.me/share/url?url=${this.referralLink}`, "_blank")
            }
        },
        switchEditMode() {
            this.$store.commit("setErrors", { password: null, profile: null });
            if (this.editMode === false) {
                this.ref_alias = this.user?.ref_alias
            }
            this.editMode = !this.editMode
        },
        async saveRefAlias() {
            this.$store.commit("setErrors", { password: null, profile: null });

            // console.log('--saveRefAlias', this.ref_alias);
            // console.log('---this..ref', this.ref_alias);
            if (!this.ref_alias || this.ref_alias.length === 0 ) {
                // console.log('---inside');
                this.switchEditMode()
                return;
            }
            this.editLoading = true
            const response = await this.$store.dispatch("updateUser", {_id: this.user._id, ref_alias: this.ref_alias});
            await this.$store.dispatch("getUser")
            // console.log('---response', response);
            this.editLoading = false
            if (response.success) {
                this.notifyVue(true, "Referral alias successfully updated");
                this.switchEditMode()
            }

        },
        copyToClipboard: function () {
            var input = document.createElement("input");
            input.setAttribute("value", this.referralLink);
            document.body.appendChild(input);
            input.select();
            var result = document.execCommand("copy");
            document.body.removeChild(input);
            this.$notify({
                message: "Link Successfully Copied",
                icon: "tim-icons icon-bell-55",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "success",
                timeout: 3000,
            });
            return result;
        },
        getReferralReports: async function (index) {
            // console.log("---type before", index);
            let type;
            if (index === 0) {
                type = "day";
            } else if (index === 1) {
                type = "week";
            } else {
                type = "month";
            }

            // console.log("---type after", type);
            this.purpleLineChart.activeIndex = index;
            // console.log("---this.getDailyStats", index);
            this.loadings = { stats: true };
            await this.$store.dispatch("getReferralReports", { type });
            this.loadings = { stats: false };
            this.formatDataTable(this.referralReports);
        },
        getReferralSummary: async function () {
            this.loadings = { summary: true };
            // console.log("--before");
            await this.$store.dispatch("getReferralSummary");
            this.loadings = { summary: false };
            // console.log("---this.referralSummary", this.referralSummary);
            this.table1.data = this.referralSummary;
        },
        shareOnFacebook() {
            const url = "https://www.facebook.com/sharer/sharer.php?u=" + encodeURIComponent(this.referralLink);
            this.openPopup(url);
        },
        shareOnTwitter() {
            const text = "Check out this link!";
            const url =
                "https://twitter.com/intent/tweet?url=" +
                encodeURIComponent(this.referralLink) +
                "&text=" +
                encodeURIComponent(text);
            this.openPopup(url);
        },
        openPopup(url) {
            const width = 700;
            const height = 500;
            const left = window.innerWidth / 2 - width / 2;
            const top = window.innerHeight / 2 - height / 2;
            window.open(url, "Share", "width=" + width + ", height=" + height + ", top=" + top + ", left=" + left);
        },
        formatDataTable: function (reports) {
            // console.log("---reports", reports);
            const chartData = {
                labels: reports.conversions.map((x) => x._id.toString()),
                datasets: [
                    {
                        label: "Conversions",
                        fill: true,
                        borderColor: config.colors.danger,
                        borderWidth: 2,
                        borderDash: [],
                        borderDashOffset: 0.0,
                        pointBackgroundColor: config.colors.danger,
                        pointBorderColor: "rgba(201, 36, 36, 0)",
                        pointHoverBackgroundColor: config.colors.danger,
                        pointBorderWidth: 20,
                        pointHoverRadius: 4,
                        pointHoverBorderWidth: 15,
                        pointRadius: 4,
                        data: reports.conversions.map((x) => x.amount),
                    },
                ],
            };

            this.$refs.chart.updateGradients(chartData);
            this.purpleLineChart.chartData = chartData;

            // console.log("---this.chartData", chartData.labels);
            return;
            return (this.table1.data = stats);
            const arr = [];
            // console.log("---data", data);
            for (const key in data) {
                const element = data[key];
                arr.push({
                    hour: key,
                    ...element,
                });
            }

            // console.log("-arr", arr);

            this.table1.data = arr;
        },
        notifyVue(success, message) {
            this.$notify({
                message,
                icon: "tim-icons icon-bell-55",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: success ? "success" : "danger",
                timeout: 5000,
            });
        },
    },
    watch: {
        user(val) {
            // console.log('---user changed', val);
            this.ref_alias = val?.ref_alias
        }
    },
};
</script>
<style scoped>


@media(max-width: 500px) {
    .referral-link-block,
    .share-block {
        justify-content: center;
    }
    
}
</style>

<!-- eslint-disable vue/no-mutating-props -->
<template>
    <modal :show="show"  :centered="false" :show-close="true">
        <div class="row px-md-2" :style="{ 'background-color': theme == 'black' ? '#28293D' : 'white' }">
            <div class="col-4 px-1">
                <img :src="model.logo" />
            </div>
            <div class="col-7 px-0">
                <div class="" style="justify-content: space-between">
                    <h4
                        :style="{ color: subTitleColor }"
                        style="font-weight: bold; margin-bottom: 0px; padding-left: 15px"
                    >
                        {{ decodeHtml(model.name) }}
                    </h4>
                </div>
                <h4 style="padding-left: 15px; font-weight: bold; color: green">{{ model.payout }}$</h4>
            </div>
            <div @click="close" style="cursor: pointer;" class="pr-0">
                <i class="far fa-times-circle mt-1 pr-0" style="font-size: 25px; color: #00DA48;"></i>
            </div>
        </div>

        <div class="mt-2" v-if="statusFormatter.length">
            <p class="text" :style="{ color: subTitleColor }" >Status:</p>
            <span v-for="stat in statusFormatter" v-html="stat"></span>
        </div>

        <div class="mt-2" v-if="pinnedFormatter.length">
            <p class="text" :style="{ color: subTitleColor }">Pinned:</p>
            <span v-for="pin in pinnedFormatter" v-html="pin"></span>
        </div>

        <div class="mt-2" :style="{ color: textColor }">
            <p class="text">Devices:</p>
            <span style="word-break: break-word">
                <svg
                    v-if="model.devices?.includes('android')"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="#C6C5CA"
                    class="bi bi-android2 mr-1"
                    viewBox="0 0 16 16"
                >
                    <path
                        d="m10.213 1.471.691-1.26c.046-.083.03-.147-.048-.192-.085-.038-.15-.019-.195.058l-.7 1.27A4.832 4.832 0 0 0 8.005.941c-.688 0-1.34.135-1.956.404l-.7-1.27C5.303 0 5.239-.018 5.154.02c-.078.046-.094.11-.049.193l.691 1.259a4.25 4.25 0 0 0-1.673 1.476A3.697 3.697 0 0 0 3.5 5.02h9c0-.75-.208-1.44-.623-2.072a4.266 4.266 0 0 0-1.664-1.476ZM6.22 3.303a.367.367 0 0 1-.267.11.35.35 0 0 1-.263-.11.366.366 0 0 1-.107-.264.37.37 0 0 1 .107-.265.351.351 0 0 1 .263-.11c.103 0 .193.037.267.11a.36.36 0 0 1 .112.265.36.36 0 0 1-.112.264Zm4.101 0a.351.351 0 0 1-.262.11.366.366 0 0 1-.268-.11.358.358 0 0 1-.112-.264c0-.103.037-.191.112-.265a.367.367 0 0 1 .268-.11c.104 0 .19.037.262.11a.367.367 0 0 1 .107.265c0 .102-.035.19-.107.264ZM3.5 11.77c0 .294.104.544.311.75.208.204.46.307.76.307h.758l.01 2.182c0 .276.097.51.292.703a.961.961 0 0 0 .7.288.973.973 0 0 0 .71-.288.95.95 0 0 0 .292-.703v-2.182h1.343v2.182c0 .276.097.51.292.703a.972.972 0 0 0 .71.288.973.973 0 0 0 .71-.288.95.95 0 0 0 .292-.703v-2.182h.76c.291 0 .54-.103.749-.308.207-.205.311-.455.311-.75V5.365h-9v6.404Zm10.495-6.587a.983.983 0 0 0-.702.278.91.91 0 0 0-.293.685v4.063c0 .271.098.501.293.69a.97.97 0 0 0 .702.284c.28 0 .517-.095.712-.284a.924.924 0 0 0 .293-.69V6.146a.91.91 0 0 0-.293-.685.995.995 0 0 0-.712-.278Zm-12.702.283a.985.985 0 0 1 .712-.283c.273 0 .507.094.702.283a.913.913 0 0 1 .293.68v4.063a.932.932 0 0 1-.288.69.97.97 0 0 1-.707.284.986.986 0 0 1-.712-.284.924.924 0 0 1-.293-.69V6.146c0-.264.098-.491.293-.68Z"
                    />
                </svg>
                <svg
                    v-if="model.devices?.includes('ios')"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="#C6C5CA"
                    class="bi bi-apple"
                    viewBox="0 0 16 16"
                >
                    <path
                        d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43Zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282Z"
                    />
                    <path
                        d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43Zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282Z"
                    />
                </svg>
            </span>
        </div>
        <div class="mt-2" :style="{ color: textColor, 'word-break': 'break-word' }">
            <p class="text" :style="{ color: subTitleColor }">Countries:</p>
            {{ model.countries.toString() }}
        </div>
        <div class="mt-2" :style="{ color: textColor, 'word-break': 'break-word' }">
            <p class="text" :style="{ color: subTitleColor }">Description:</p>
            {{ decodeHtml(model.description )}}
        </div>
        <div class="mt-2" :style="{ color: textColor, 'word-break': 'break-word' }">
            <p class="text" :style="{ color: subTitleColor }">Instructions</p>
            {{ decodeHtml(model.instructions )}}
        </div>
        <div class="px-3 mt-4" style="display: flex; flex-direction: row; justify-content: center">
            <base-button class="mr-3" @click="close" slot="footer" type="primary" fill>
                <span>Close</span>
            </base-button>
        </div>
    </modal>
</template>
<script>
import Modal from "@/components/Modal";

import { BaseAlert } from "@/components";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

import "./index.css";
import { decodeHtmlEntities } from "../../services/utils";
export default {
    components: {
        BaseAlert,
        Modal,
        ClipLoader,
    },
    data() {
        return {
            theme: localStorage.getItem("theme"),
        };
    },
    computed: {
        textColor() {
            return this.theme == "black" ? "rgba(255, 255, 255, 0.7) !important" : "#28293D";
        },
        subTitleColor() {
            return this.theme == "black" ? "rgba(255, 255, 255, 0.8)" : "#222a42";
        },
        statusFormatter() {
            // console.log('---this.selectedContest', this.selectedContest);
            if (this.selectedContest === "all") {
                const data = []
                for (let i = 0; i < this.contests.length; i++) {
                    const contest = this.contests[i];
                    // console.log('---contest', contest, this.model._id);
                    if (contest.excluded_offers.includes(this.model._id)) {
                        data.push(
                            `Disabled on <strong>${contest.title}</strong> <br />`
                        )
                    }
                }
                // console.log('---data', data.length > 0);

                return data
            }
            return []
        },
        pinnedFormatter() {
            // console.log('---this.selectedContest', this.selectedContest);
            if (this.selectedContest === "all") {
                const data = []
                for (let i = 0; i < this.contests.length; i++) {
                    const contest = this.contests[i];
                    // console.log('---contest', contest, this.model._id);
                    if (contest.pinned_offers.includes(this.model._id)) {
                        data.push(
                            `Pinned on <strong>${contest.title}</strong> <br />`
                        )
                    }
                }
                // console.log('---data', data);
                return data
            }
            return []
        }
    },
    props: {
        model: {
            type: Object,
            default: () => {
                return {};
            },
        },
        show: {
            type: Boolean,
            default: false,
        },
        close: {
            type: Function,
            default: false,
        },
        contests: Array,
        selectedContest: String
    },
    methods: {
        decodeHtml(value) {
            return decodeHtmlEntities(value)
        },
    }
};
</script>
<style></style>

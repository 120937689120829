<template>
    <div>
        <div class="row mx-0 ">
            <div class="col-md-7 row justify-content-between ">
                <base-button
                    class="mb-3 "
                    @click="goBack"
                    size="sm"
                    :type="'secondary'"
                    fill
                >
                    <i class="fas fa-arrow-left mr-2"></i>
                    Back
                </base-button>
                <div class="row">
                    <base-button
                        v-if="$route.name != 'createTheme'"
                        class="mb-3 mr-2"
                        size="sm"
                        :type="'success'"
                        @click="copyPublicLink"
                        fill
                    >
                        <span>Copy Public Link</span>
                    </base-button>
                    <base-button
                        class="mb-3 "
                        @click="save"
                        size="sm"
                        type="primary"
                        fill
                    >
                        <clip-loader v-if="formLoading" color="white" size="25px" :loading="formLoading"></clip-loader>
                        <span v-else="">Save</span>
                    </base-button>
                </div>

            </div>

        </div>
        <div class="row">
            <div class="col-md-7">
                <settings
                    view="contest"
                    :device="device"
                    :setDevice="setDevice"
                    :error="errors.contest"
                    :onChange="onChange"
                    :addAction="addAction"
                    :removeAction="removeAction"
                    :model="addContestModel"
                    :success="success"
                    :formLoading="loads?.updateContest"
                    :save="save"
                    :entries="contestEntries"
                    :actionValidationErrors="actionValidationErrors"
                >
                </settings>
            </div>
            <div class="col-md-5">
                <preview
                    v-if="addContestModel"
                    :initialized="contestInitialized"
                    :device="device"
                    :contest="addContestModel"
                    :demoOffers="offersDemos"
                ></preview>
            </div>
        </div>
    </div>

</template>
<script>
import Settings from "./Contest/Settings";
import UserCard from "./Profile/UserCard";
import { Preview } from "@/components";
import { mapGetters } from "vuex";
import moment from "moment";
import {
    getTweetId,
    isFacebookUrl,
    isTwitterUrl,
    isValidHttpUrl,
    isValidInstagramProfileUrl,
    isValidInstagramPostUrl,
    isValidTikTokVideoUrl,
    isValidTikTokUsername,
    isValidYouTubeUrl,
    isValidYouTubeChannel,
isValidDiscordInvite,
isValidTelegramGroupInvite,
isValidTelegramChannelLink,
isValidTwitchLink,
copyToClipboard
} from "../services/utils";

export default {
    components: {
        Settings,
        UserCard,
        Preview,
    },
    data() {
        return {
            contestInitialized: false,
            addContestModel: {
                title: null,
                logo: null,
                // payout_threshold: 0,
                description: null,
                media: {
                    link: "",
                    type: "unkown",
                },
                is_social_active: true,
                date: {
                    start: moment().toDate(),
                    end: moment().add(1, "week").toDate(),
                },
                actions: [],
                link_alias: "",
                main_color: "#333333",
                background_image: '',
                theme: {
                    active: false,
                    media: null
                }
            },
            contestLinkRefreshor: 0,
            loading: false,
            formLoading: false,
            success: false,
            device: "desktop",
            actionValidationErrors: [],
        };
    },
    computed: {
        ...mapGetters(["contest", "errors", "successMessages", "loads", "contestEntries", "offersDemos"]),
        errorMessage() {
            return this.errors.contest;
        },
        contestLink() {
            return (
                this.contest.link +
                (this.contest.link?.includes("?") ? "&" : "?") +
                `&refreshor= + ${this.contestLinkRefreshor}&screen=${this.device}`
            );
        },
    },
    mounted() {
        if (this.$route.params.id !== "new") {
            this.getContest();
        } else {
            this.contestInitialized = true;
        }
        this.getEntries();
        this.getOffersDemos();
    },
    methods: {
        getEntries: async function () {
            this.loading = true;
            await this.$store.dispatch("getContestEntries");
            this.loading = false;
        },
        getContest: async function () {
            this.loading = true;
            await this.$store.dispatch("getContest", this.$route.params.id);
            // console.log("--after", this.contest);
            if (this.contest) {
                const isTheme = this.$route.name == "createTheme";

                const addContestModel = {
                    ...this.contest,
                }
                if (isTheme) {
                    addContestModel.date = {
                        start: moment().toDate(),
                        end: moment().add(1, "week").toDate(),
                    }
                    addContestModel.link_alias = null;
                    addContestModel.winners = [];
                } else {
                    addContestModel.date = {
                        start: moment(this.contest.date.start).toDate(),
                        end: moment(this.contest.date.end).toDate(),
                    }
                }
                
                this.addContestModel = addContestModel;

                this.loading = false;
                this.contestInitialized = true;
            } else {
                this.$router.replace("/not-found");
            }
        },
        updateContest: async function () {
            const isValid = this.validateActionsEntries();
            // console.log("--isValid", isValid);
            if (!isValid) return;
            this.formLoading = true;
            await this.$store.dispatch("updateContest", { ids: [this.$route.params.id], ...this.addContestModel });
            this.contestLinkRefreshor += 1;
            await this.getContest();
            this.formLoading = false;
            // console.log("----this.success.contest", this.successMessages);
            if (this.successMessages.contest) {
                this.notifyVue(true, this.successMessages.contest);
            }
        },
        addContest: async function () {
            const { title, description, date } = this.addContestModel;
            if (!title || !description || !date?.start || !date.end) {
                // show error message
                this.$store.commit("setErrors", { contest: "Some required fields are missed" });
                return;
            }
            const isValid = this.validateActionsEntries();
            if (!isValid) return;

            this.loading = true;
            const result = await this.$store.dispatch("createContest", this.addContestModel);
            if (result) {
                if (this.successMessages.contest) {
                    this.notifyVue(true, this.successMessages.contest);
                }
                this.loading = false;
                this.$router.push(`/contests`);
            }
        },
        getOffersDemos: async function () {
            await this.$store.dispatch("getOffersDemos", {});
        },
        save: async function () {
            const id = this.$route.params.id;
            const isTheme = this.$route.name == "createTheme";
            if (id === "new" || isTheme ) {
                this.addContest();
            } else {
                this.updateContest();
            }
        },
        notifyVue(success, message) {
            this.$notify({
                message,
                icon: "tim-icons icon-bell-55",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: success ? "success" : "danger",
                timeout: 5000,
            });
        },
        goBack() {
            this.$router.push("/contests")
        },
        copyPublicLink() {
            // console.log('---this.model', this.addContestModel);
            let value;
            if (this.addContestModel?.link_alias) {
                value = `https://adviral.link/${this.addContestModel.link_alias}`;
            } else {
                // const baseUrl = window.location.href.endsWith("/") ? window.location.href : window.location.href + "/";
                value = `https://adviral.link/contest/${this.addContestModel._id}/public`;
            }
            copyToClipboard(value)
            this.$notify({
                message: "Link Successfully Copied",
                icon: "tim-icons icon-bell-55",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "success",
                timeout: 3000,
            });
        },
        addAction(action) {
            // if (!this.addContestModel.actions.find((x) => x.key == action.key)) {
                this.addContestModel.actions.push({
                    ...action,
                    key_index: `${action.key}-${this.addContestModel.actions.length}`,
                    inputs: action.inputs.map(x => ({...x}))
                });
            // }

            // console.log("---THIS.", this.addContestModel);
        },
        removeAction(action) {
            const arr = [...this.addContestModel.actions];
            const index = arr.findIndex((x) => x.key_index == action.key_index);
            // console.log("---removeAction", index, action);
            if (index > -1) {
                arr.splice(index, 1);
            }

            this.addContestModel.actions = arr;

            // console.log("---THIS.", this.addContestModel);
        },
        onChange(key, val) {
            // console.log("----onChange key, value", key, val);
            // if (key == "is_social_active") {
            //     this.updateContest();
            // }
            if (key == "theme.active") {
                this.addContestModel.theme.active = val
            } else if (key == "theme.media") {
                this.addContestModel.theme.media = val
            } else {
                this.addContestModel[`${key}`] = val;
            }
        },
        reloadPreview() {
            this.contestLinkRefreshor += 1;
        },
        setDevice(val) {
            this.device = val;
        },
        validateActionsEntries() {
            this.actionValidationErrors = [];
            const actions = this.addContestModel.actions;
            for (let i = 0; i < actions.length; i++) {
                const action = actions[i];
                // console.log("--action", action);
                for (let j = 0; j < action.inputs.length; j++) {
                    const input = action.inputs[j];
                    const value = input.value?.toLowerCase();

                    if (input.required && !input.value) {
                        this.actionValidationErrors.push({
                            key_index: action.key_index,
                            message: `${input.title} is required`,
                        });

                        return;
                    }
                    if (
                        ["facebook-like-page"].includes(action.key) &&
                        input.title === "Page Url" &&
                        !isFacebookUrl(value)
                    ) {
                        this.actionValidationErrors.push({
                            key_index: action.key_index,
                            message: "Please enter a valid facebook page url",
                        });
                        return;
                    }

                    if (["facebook-visit", "facebook-view"].includes(action.key)) {
                        if (input.title !== "Page name" && !isFacebookUrl(value)) {
                            this.actionValidationErrors.push({
                                key_index: action.key_index,
                                message: "Please enter a valid facebook post url",
                            });
                            return false;
                        }
                    }

                    if (["twitter-view"].includes(action.key)) {
                        if (!isTwitterUrl(value)) {
                            this.actionValidationErrors.push({
                                key_index: action.key_index,
                                message: "Please enter a valid tweet url",
                            });
                            return false;
                        }
                    }
                    if (["twitter-comment", "twitter-retweet"].includes(action.key)) {
                        if (!isTwitterUrl(value) && !getTweetId(value)) {
                            this.actionValidationErrors.push({
                                key_index: action.key_index,
                                message: "Please enter a valid tweet url",
                            });
                            return false;
                        }
                    }

                    if (action.key === "twitter-share") {
                        if (!value || !isValidHttpUrl(value)) {
                            this.actionValidationErrors.push({
                                key_index: action.key_index,
                                message: "Please enter a valid link",
                            });
                            return false;
                        }
                    }

                    if (
                        ["instagram-share", "instagram-comment", "instagram-like", "instagram-view"].includes(
                            action.key,
                        )
                    ) {
                        if (!value || !isValidInstagramPostUrl(value)) {
                            this.actionValidationErrors.push({
                                key_index: action.key_index,
                                message: "Please enter a valid link",
                            });
                            return false;
                        }
                    }

                    if (["instagram-follow"].includes(action.key)) {
                        if (!value) {
                            this.actionValidationErrors.push({
                                key_index: action.key_index,
                                message: "Please enter a valid username",
                            });
                            return false;
                        }
                    }

                    if (["tiktok-share", "tiktok-comment", "tiktok-like"].includes(action.key)) {
                        if (!value || !isValidTikTokVideoUrl(value)) {
                            this.actionValidationErrors.push({
                                key_index: action.key_index,
                                message: "Please enter a valid link",
                            });
                            return false;
                        }
                    }

                    if (["tiktok-follow"].includes(action.key)) {
                        if (!value || !isValidTikTokUsername(value)) {
                            this.actionValidationErrors.push({
                                key_index: action.key_index,
                                message: "Please enter a valid username",
                            });
                            return false;
                        }
                    }

                    if (["youtube-comment", "youtube-watch", "youtube-like"].includes(action.key)) {
                        if (!value || !isValidYouTubeUrl(value)) {
                            this.actionValidationErrors.push({
                                key_index: action.key_index,
                                message: "Please enter a valid link",
                            });
                            return false;
                        }
                    }

                    if (["youtube-subscribe"].includes(action.key) && input.title == "Channel link") {
                        if (!value || !isValidYouTubeChannel(value)) {
                            this.actionValidationErrors.push({
                                key_index: action.key_index,
                                message: "Please enter a valid channel link",
                            });
                            return false;
                        }
                    }

                    if (["discord-join"].includes(action.key)) {
                        if (!value || !isValidDiscordInvite(value)) {
                            this.actionValidationErrors.push({
                                key_index: action.key_index,
                                message: "Please enter a valid link",
                            });
                            return false;
                        }
                    }

                    if (["telegram-channel"].includes(action.key)) {
                        if (!value || !isValidTelegramChannelLink(value)) {
                            this.actionValidationErrors.push({
                                key_index: action.key_index,
                                message: "Please enter a valid link",
                            });
                            return false;
                        }
                    }

                    if (["telegram-group"].includes(action.key)) {
                        if (!value || !isValidTelegramGroupInvite(value)) {
                            this.actionValidationErrors.push({
                                key_index: action.key_index,
                                message: "Please enter a valid link",
                            });
                            return false;
                        }
                    }

                    if (["twitch-follow"].includes(action.key)) {
                        if (!value) {
                            this.actionValidationErrors.push({
                                key_index: action.key_index,
                                message: "Please enter a valid account",
                            });
                            return false;
                        }
                    }

                    if (["twitch-subscribe"].includes(action.key) && input.title == "Channel link") {
                        if (!value || !isValidTwitchLink(value)) {
                            this.actionValidationErrors.push({
                                key_index: action.key_index,
                                message: "Please enter a valid link",
                            });
                            return false;
                        }
                    }
                }
            }

            return true;
        },
    },
};
</script>
<style></style>

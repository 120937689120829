<!-- eslint-disable vue/no-mutating-props -->
<template>
    <div style="width: 100%">
        <card>
            <div class="row justify-content-between align-items-center ml-0 mb-4">
                <ul class="nav nav-pills">
                    <li class="nav-item">
                        <a
                            slot="header"
                            class="nav-link title"
                            :class="{ 'sub-tab-active': tab == 'setup' }"
                            @click="setTab('setup')"
                            aria-current="page"
                            style="cursor: pointer"
                            >Setup</a
                        >
                    </li>
                    <li class="nav-item">
                        <a
                            slot="header"
                            class="nav-link title"
                            :class="{ 'sub-tab-active': tab == 'enter' }"
                            @click="setTab('enter')"
                            style="cursor: pointer"
                        >
                            <i
                                class="tim-icons icon-alert-circle-exc mr-1"
                                :style="{ color: tab === 'enter' ? 'unset' : 'red' }"
                                v-if="this.model.is_social_active && this.model.actions.length === 0"
                            ></i>
                            Social shares</a
                        >
                    </li>
                </ul>

                <!-- <h5 slot="header" class="title">Theme</h5> -->
            </div>
            <h5 class="text-danger mt-4 text-center" style="font-size: 1em; font-weight: bold;" v-if="error">{{ error }}</h5>

            <div v-if="tab == 'setup'">
                <div class="row">
                    <div class="col-md-6 px-md-2 form-group"
                        v-click-outside="() => handleOutsideDateClick('start')"
                    >
                        <p class="control-label bold">Start *</p>
                        <date-picker
                            :format="'YYYY-MM-DD hh:ss'"
                            :class="{ 'picker-input-black': theme == 'black' }"
                            v-model="model.date.start"
                            type="datetime"
                            style="width: 100%"
                            :open="startDateOpen"
                            @change="(val, type) => onDateChange(val, type, 'start')"
                            @calendar-change="(val, type) => onDateChange(val, 'calendar', 'start')"
                            @panel-change="(val, type) => onDateChange(val, 'panel', 'start')"
                            @focus="openDatePicker('start')"

                        ></date-picker>
                    </div>
                    <div class="col-md-6 px-md-2"
                        v-click-outside="() => handleOutsideDateClick('end')"
                    >
                        <p class="control-label bold">End *</p>
                        <date-picker
                            :format="'YYYY-MM-DD hh:ss'"
                            :class="{ 'picker-input-black': theme == 'black' }"
                            v-model="model.date.end"
                            type="datetime"
                            style="width: 100%"
                            :open="endDateOpen"
                            @change="(val, type) => onDateChange(val, type, 'end')"
                            @calendar-change="(val, type) => onDateChange(val, 'calendar', 'end')"
                            @panel-change="(val, type) => onDateChange(val, 'panel', 'end')"
                            @focus="openDatePicker('end')"
                        ></date-picker>
                    </div>
                    <div class="col-md-12 px-md-2">
                        <base-input source="offer" label="Title *" placeholder="Title" v-model="model.title">
                        </base-input>
                    </div>
                    <div class="col-md-12 px-md-2">
                        <base-input
                            source="offer"
                            label="Media Link"
                            placeholder="https://example.com/image.png"
                            v-model="model.media.link"
                            :helperText="'Allowed links: videos, images, gifs, youtube videos and tiktok videos'"
                        >
                        </base-input>
                    </div>
                    <div class="col-md-12 px-md-2">
                        <base-input
                            source="offer"
                            label="Background Image Link"
                            placeholder="https://example.com/background-image-1920-1080.png"
                            v-model="model.background_image"
                            :helperText="'Recommended size: 1920/1080 px '"
                        >
                        </base-input>
                    </div>
                    <div class="col-md-12 px-md-2">
                        <base-text-area
                            source="offer"
                            rows="5"
                            label="Description *"
                            placeholder="Description"
                            v-model="model.description"
                        >
                        </base-text-area>
                    </div>
                    <!-- <div class="col-md-12 px-md-2">
                        <base-input
                            source="offer"
                            label="Payout Threshold"
                            type="number"
                            placeholder="1"
                            v-model="model.payout_threshold"
                        >
                        </base-input>
                    </div> -->

                    <div class="col-md-12 px-md-2">
                        <base-input source="offer" label="Custom domain" placeholder="custom" v-model="model.link_alias">
                        </base-input>
                    </div>
                    <div class="col-md-12 px-md-2">
                        <v-swatches show-fallback fallback-input-class="my-1" fallback-ok-class="btn-primary"
                            v-model="model.main_color" style="width: 100%;" swatches="text-advanced">
                            <base-input label="Main Color" source="offer" placeholder="Click to Select Color"
                                v-model="model.main_color" slot="trigger">
                            </base-input>
                        </v-swatches>
                    </div>
                    <div class="col-md-12 px-md-2">
                        <base-input
                            source="offer"
                            label="Title size"
                            type="number"
                            placeholder="20"
                            v-model="model.title_font_size"
                            :helperText="'Size in Pixel (px)'"
                        >
                        </base-input>
                    </div>
                    <div class="col-md-12 px-md-2">
                        <base-switch
                            :source="'offer'"
                            :label="'Enable social entries'"
                            :value="model.is_social_active"
                            :onChange="(val) => onChange(`is_social_active`, val)"
                        ></base-switch>
                    </div>
                    <div class="col-md-12 px-md-2" v-if="user?.is_admin">
                        <base-switch
                            :source="'offer'"
                            :label="'Is a theme?'"
                            :value="model.theme.active"
                            :onChange="(val) => onChange(`theme.active`, val)"
                        ></base-switch>
                    </div>
                    <div class="col-md-12 px-md-2" v-if="user?.is_admin && model.theme.active">
                        <!-- <base-switch
                            :source="'offer'"
                            :label="''"
                            :value="model.theme.media"
                            :onChange="(val) => onChange(`theme.media`, val)"
                        ></base-switch> -->
                        <base-input
                            source="offer"
                            label="Theme Image"
                            placeholder="https://example.com/image-1920-1080.png"
                            v-model="model.theme.media"
                        >
                        </base-input>
                    </div>
                    
                </div>
            </div>

            <div v-if="tab == 'enter'">
                <div class="row px-3" v-for="(action, index) in model.actions" :class="{ 'mt-2': index > 0 }">
                    <action-input
                        :onChange="onChange"
                        :removeAction="removeAction"
                        :action="action"
                        :error="actionValidationErrors.find((x) => x.key_index === action.key_index)?.message"
                    ></action-input>
                </div>
                <div
                    class="row justify-content-center align-items-center"
                    style="color: red; font-size: 16px"
                    v-if="this.model.is_social_active && this.model.actions.length === 0"
                >
                    <i class="tim-icons icon-alert-circle-exc mr-1"></i>
                    You do not have any social shares yet, please add them from the list below
                </div>
                <div class="mx-0 mt-4">
                    <div class="row mx-0 align-items-start" style="font-size: 16px">
                        <i class="tim-icons icon-simple-add mr-2 mt-1"></i>
                        <h4 style="font-weight: 600">Add social entries:</h4>
                    </div>
                    <div class="row mx-0">
                        <div class="mr-1 mt-1" v-for="entry in entries">
                            <action-button :ref="`action-button-${entry._id}`" :entry="entry" :closeActionsButton="closeActionsButton" :addAction="addAction"></action-button>
                        </div>
                    </div>
                </div>
            </div>

        </card>
    </div>
</template>
<script>
import { BaseAlert, BaseSwitch, BaseInput, BaseTextArea } from "@/components";
import VSwatches from "vue-swatches";
import "vue-swatches/dist/vue-swatches.css";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "./index.css";
import { mapGetters } from "vuex";
import vClickOutside from "v-click-outside";
import Vue from 'vue'
Vue.use(vClickOutside);

import ActionButton from "../../components/Contests/ActionButton.vue";
import ActionInput from "../../components/Contests/ActionInput.vue";

export default {
    components: {
        BaseAlert,
        VSwatches,
        BaseSwitch,
        ClipLoader,
        BaseTextArea,
        DatePicker,
        ActionButton,
        ActionInput,
    },
    directives: {
        clickOutside: vClickOutside.directive,
    },
    data: function () {
        return {
            color: "#ffffff",
            colors: "#194D33A8", // etc: { h: 150, s: 0.66, v: 0.30 }, { r: 255, g: 0, b: 0 }, '#194d33'
            categories: ["cpi", "cpa", "pin", "cpe", "surveys"],
            tab: "setup",
            subTab: "desktop",
            theme: localStorage.getItem("theme"),
            startDateOpen: false,
            endDateOpen: false,

        };
    },

    props: {
        
        model: {
            type: Object,
            default: () => {
                return {
                    date: {},
                };
            },
        },
        entries: Array,
        success: {
            type: Boolean,
            default: false,
        },
        view: {
            type: String,
        },
        formLoading: {
            type: Boolean,
            default: false,
        },
        save: {
            type: Function,
            default: () => {},
        },
        onChange: {
            type: Function,
            default: () => {},
        },
        addAction: {
            type: Function,
            default: () => {},
        },
        removeAction: {
            type: Function,
            default: () => {},
        },
        error: {
            type: String,
        },
        setDevice: Function,
        device: String,
        actionValidationErrors: Array,
    },
    computed: {
        publicLink() {
            return `https://${window.location.host}/${this.model.link_alias}`;
        },
        ...mapGetters(["user"]),
    },
    methods: {
        setTab(tab) {
            this.tab = tab;
        },
        setSubTab(subTab) {
            this.subTab = subTab;
            this.setDevice(subTab);
        },
        getThemeSetting(type, key) {
            return this.model.settings?.theme?.[type]?.[key];
        },
        copyPublicLink() {
            var input = document.createElement("input");
            if (this.model?.link_alias) {
                input.setAttribute("value", `https://adviral.link/${this.model.link_alias}`);
            } else {
                // const baseUrl = window.location.href.endsWith("/") ? window.location.href : window.location.href + "/";
                input.setAttribute("value", `https://adviral.link/contest/${this.model._id}/public`);
            }
            document.body.appendChild(input);
            input.select();
            var result = document.execCommand("copy");
            document.body.removeChild(input);
            this.$notify({
                message: "Link Successfully Copied",
                icon: "tim-icons icon-bell-55",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: "success",
                timeout: 3000,
            });
            return result;
        },
        closeActionsButton(currentEntryId) {
            for (let i = 0; i < this.entries.length; i++) {
                const entry = this.entries[i];
                if (entry._id != currentEntryId) {
                    const actionButtonRef = this.$refs[`action-button-${entry._id}`][0];
                    // console.log('actionButtonRefactionButtonRef', {actionButtonRef});
                    actionButtonRef.closeShowActions()
                }

            }
        },
        containsInvalidCharacters(input) {
            const invalidCharactersRegex = /[^\d-:\s]/;
            return invalidCharactersRegex.test(input);
        },
        onDateChange(date, type, input) {
            // console.log('----onDateChange', date, type, input);
            if (["calendar", "date", "hour", "panel"].includes(type)) {
                this.waitForSeconds = true
                setTimeout(() => {
                    this.waitForSeconds = false
                }, 0)
            }
            if (type === "second") {
                // this.$refs.datePickerEndDate.open = false
                this[`${input}DateOpen`] = false
            }
        },
        openDatePicker(input) {
            const opposite = input === "start" ? "end" : "start"
            this[`${input}DateOpen`] = true
            this[`${opposite}DateOpen`] = false
        },
        handleOutsideDateClick(type) {
            // console.log('---outside type', type);
            if (type == "start" && this.startDateOpen && !this.waitForSeconds) {
                // console.log('---outside', type);
                this.startDateOpen = false;
            } 
            else if (type == "end" && this.endDateOpen && !this.waitForSeconds) {
                this.endDateOpen = false;
            }
        }
    },
    watch: {
        actionValidationErrors(val) {
            if (val?.length > 0) {
                this.setTab("enter");
            }
        },
        model(val) {
            const {start, end} = val?.date
            // console.log('---model', this.model.link_alias);
            // console.log('---change start end', {start, end});
            const invalidCharactersRegex = /[^\d-:\s]/;
            if (this.containsInvalidCharacters(start) && typeof this.model.date?.start?.replace === "function") {
                this.model.date.start = this.model.date?.start?.replace(invalidCharactersRegex, '');
            }
            if (this.containsInvalidCharacters(end) && typeof this.model.date?.end?.replace === "function") {
                this.model.date.end = this.model.date?.end?.replace(invalidCharactersRegex, '');
            }
        }
    },
};
</script>
<style></style>

<template>
    <div class="row">
      <div class="col-12">
        <card :title="table1.title">
          <div class="row justify-content-end ">
            <div class="row col-12 px-0 justify-content-start">
              <base-select source="offer" :onChange="(val) => onFilterChange('fraud_type', val)" :model="fraud_type" :label="fraudTypeFilter.label"
                class="mr-2 w-25 w-full" :options="fraudTypeFilter.options" :default="fraud_type"></base-select>
              <base-select source="offer" :onChange="(val) => onFilterChange('timebox', val)" :model="timebox" :label="timeBoxFilter.label"
                class="mr-2 w-25 w-full" :options="timeBoxFilter.options" :default="timebox"></base-select>
            </div>
          </div>

          <div class="row col-12 px-0 justify-content-start mt-2">
            <base-input
                  v-model="filters.public_id"
                  :placeholder="'public id'"
                  class="mr-2 col-md-3"
                  label="Filter by public id"
                  source="offer"
                  :value="filters.public_id"
              ></base-input>
            <base-input
                  v-model="filters.username"
                  :placeholder="'username'"
                  class="mr-2 col-md-3"
                  source="offer"
                  label="Filter by username"
                  :value="filters.username"
              ></base-input>
              <div class="row col-5 col-md-6 justify-content-end align-items-center">
                <base-button @click="getViolators"  :type="'primary'" fill>Apply filters</base-button>
                  <base-button @click="clearFilters"  :type="'secondary'" fill>Clear filters</base-button>
              </div>
          </div>
  
          <div class="table-responsive mt-4">
            <violator-table
              :currentPage="currentPage"
              :perPage="perPage"
              :data="table1.data" 
              :columns="table1.columns"
              thead-classes="text-primary"
              :loadings="loadings"
              :openAction="openAction"
            >
            </violator-table>
            <div v-if="table1?.data?.length > 0">
              <pagination
                :totalItems="table1.data.length"
                :currentPage="currentPage"
                :onSelect="selectPage"
                :perPage="perPage"
                :onPerPageChange="onPerPageChange"
              ></pagination>
          </div>
          <div v-else class="row justify-content-center">
            <span>No results found</span>
          </div>
          </div>
        </card>
      </div>
  
    </div>
  </template>
  <script>
  
  import { ViolatorTable } from "@/components";
  import { BaseSelect } from "@/components";
  import { Pagination } from "@/components";
  import { mapGetters } from 'vuex';
  import NotificationTemplate from './Notifications/NotificationTemplate';
  
  const tableColumns = [ "id", "Status", "Username", "Date Joined", "Earnings", "Referrer", "Failed Checks", "control"];
  const fraud_types = [
    {value: "BAD_ISP", title: "Bad isps"},
    {value: "android_version", title: "Android versions"},
  ]
  const initialFilters = {
          public_id: '',
          username: ''
        }
  
  export default {
    components: {
      ViolatorTable,
      BaseSelect,
      Pagination
    },
    mounted() {
      this.fraudTypeFilter.options = [...this.fraudTypeFilter.options, ...fraud_types]
      this.getViolators()
    },
    updated() {
    },
    data() {
      return {
        table1: {
          title: "Violators",
          columns: [...tableColumns],
          data: []
        },
        fraudTypeFilter: {
          label: "Fraud Check module",
          name: "fraud_type",
          options: [{ title: "ALL", value: "all" }],
        },
        fraud_type: "all",
        timeBoxFilter: {
          label: "Last X days",
          name: "timebox",
          options: [{ title: "ALL", value: "all" }, { title: "Last 7 days", value: "7"}, {title: "Last month", value: "30"}]
        },
        timebox: "all",
        filters: {...initialFilters},
        currentPage: 1,
        perPage: 10,
        loadings: null
        
      };
    },
    computed: {
      ...mapGetters([
        'violators',
      ])
    },
    methods: {
      getViolators: async function () {
        this.loading = true;
        const params = {
          fraud_type: this.fraud_type !== "all" ? this.fraud_type : undefined,
          timebox: this.timebox !== "all" ? this.timebox : undefined,
          ...this.filters
        }
        await this.$store.dispatch("getViolators", params);
        this.table1.data = [...this.violators]
        this.loading = false;
        this.currentPage = 1
      },
      onFilterChange: function(key, val) {
        this[key] = val;
        // this.getViolators()
      },
      selectPage: function (page) {
        this.currentPage = page
      },
      onPerPageChange: function (val) {
        console.log('-onPerPageChange', val)  
        if (!isNaN(val)) {
          this.perPage = val;
          this.currentPage = 1
        } 
      },
      clearFilters: function () {
        // console.log('--this.filters before', this.filters);
        this.filters = {...initialFilters}
        // console.log('--this.filters before', this.filters);
      },
      openAction(action) {
        console.log('---openAction', action);
      },
      notifyVue(success, message) {
        this.$notify({
            message: message,
            icon: "tim-icons icon-bell-55",
            horizontalAlign: "center",
            verticalAlign: "top",
            type: success ? "success" : "danger",
            timeout: 5000
          });
        }
    },
  
  };
  </script>
  <style>
  
  </style>
  
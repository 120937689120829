<!-- eslint-disable vue/no-mutating-props -->
<template>
    <div class="row mt-4">
        <div class="col-12 col-md-8" v-if="selectedArticle">
            <!-- <p>{{ selectedArticle.content }}</p> -->
            <h2>{{ category.title }} - {{ selectedArticle.title }}</h2>
            <img :src="selectedArticle.image" style="width: 100%;"/>
            <div class="my-4 html-section" v-html="selectedArticle.content"></div>
        </div>
        <div class="col-12 col-md-4" style="height: 500px; overflow-y: scroll">
            <div
                class="row mb-2 next-video-card mx-0 px-0"
                style="cursor: pointer; height: auto"
                :style="{ 'border-color': video?._id == selectedArticle?._id ? '#00DA48' : 'unset' }"
                v-for="(video, index) in category.articles"
                @click="selectArticle(video)"
            >
                <div class="col-md-4 px-0" style="max-height: 100px;">
                    <img src="https://i.ytimg.com/vi_webp/15_DCzw_vHU/maxresdefault.webp" style="height: 100%" />
                </div>
                <div
                    class="col-md-8 py-1"
                    style="display: flex; flex-direction: column; justify-content: space-between"
                    :style="{ opacity: video?._id == selectedArticle?._id ? 0.7 : 1 }"
                >
                    <div
                        style="font-weight: 600; font-size: 18px"
                        :style="{
                            color:
                                video?._id == selectedArticle?._id
                                    ? '#00DA48'
                                    : theme == 'black'
                                    ? 'rgba(255, 255, 255, 0.8)'
                                    : 'unset',
                        }"
                    >
                        {{  video.title }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import "./player.css";
// import marked from "marked";

export default {
    components: {},
    created() {},
    data() {
        return {
            selectedArticle: null,
            theme: localStorage.getItem("theme"),
        };
    },
    props: {
        category: Object,
    },
    mounted() {
        this.selectedArticle = this.category.articles[0];
    },
    computed: {
        renderedMarkdown() {
            return this.selectedArticle?.content ? this.selectedArticle?.content : "";
        },
    },
    methods: {
        selectArticle(item) {
            this.selectedArticle = item;
        },
    },
    watch: {
        category(val) {
            this.selectedArticle = val.articles[0];
        },
    },
};
</script>
<style></style>

<template>
    <div class="col-md-12 px-0 py-0 justify-between action-input-container">
        <div
            class="row mx-0 justify-content-between align-items-center px-3 py-1 action-input-container-title"
            style="cursor: pointer"
            :style="{'border-bottom-right-radius': showDetails || error ? 0 : '15px', 'border-bottom-left-radius': showDetails || error ? 0 : '15px', 'background-color': backgroundColor}"
            @click="switchShowDetails()"
        >
            <h4 class="mb-0 bold text-white">
                <i class="tim-icons mr-2" :class="showDetails ? 'icon-minimal-down' : 'icon-minimal-right'" ></i>
                <i :class="action.entry.style?.icon" class="mr-1"></i>

                {{ action.title }}
            </h4>

            <base-button size="sm" slot="footer" style="border-color: white;" simple="true" type="" fill="white" @click="removeAction(action)">
                <span class="text-white">Remove</span>
            </base-button>
        </div>

        <h5 class="text-danger mt-4 ml-2 bold" style="font-size: 1em" v-if="error">{{ error }}</h5>
        <div class="row my-1 px-2" v-if="showDetails || error">
            <div
                class="mx-0 col-md-6 mb-2"
                v-for="input in action.inputs"
                :class="{ 'col-md-12': input.type === 'textarea' }"
            >
                <base-text-area
                    v-if="input.type === 'textarea'"
                    source="offer"
                    :label="input.title"
                    :placeholder="input.placeholder"
                    v-model="input.value"
                    :helper-text="'Max characters: 280'"
                >
                </base-text-area>
                <base-input
                    v-else
                    source="offer"
                    :label="input.title"
                    :placeholder="input.placeholder"
                    v-model="input.value"
                >
                </base-input>
            </div>
            <div class="mx-0 col-md-6">
                <base-input source="offer" label="Number of entries" v-model="action.amount"> </base-input>
            </div>
        </div>
    </div>
</template>
<script>
import BaseInput from "../Inputs/BaseInput.vue";
import BaseTextArea from "../Inputs/BaseTextArea.vue";
import BaseButton from "../BaseButton.vue";

import "./index.css";
export default {
    name: "action-button",
    components: {
        BaseInput,
        BaseButton,
        BaseTextArea,
    },
    props: {
        action: Object,
        onchange: Function,
        removeAction: Function,
        error: String,
    },
    data() {
        return {
            showDetails: false,
        };
    },
    computed: {
        backgroundColor() {
            return this.action.entry.style?.color
        }  
    },
    methods: {
        switchShowDetails() {
            // console.log("--this.show");
            this.showDetails = !this.showDetails;
        },
    },
};
</script>
<style></style>

<!-- eslint-disable vue/no-mutating-props -->
<template>
    <modal :show="show"  :centered="false" :show-close="true" overflowY="auto">
        <div class="row px-md-2 justify-content-between align-items-center" :style="{ 'background-color': theme == 'black' ? '#28293D' : 'white' }">
            <div class="col-8 px-0">
                <div class="" style="justify-content: space-between">
                    <h4
                        :style="{ color: subTitleColor }"
                        style="font-weight: 600; margin-bottom: 0px; padding-left: 15px"
                    >
                        Are you sure?
                    </h4>
                </div>
            </div>
            <div @click="close" style="cursor: pointer;" class="pr-0">
                <i class="far fa-times-circle mt-1 pr-0" style="font-size: 25px; color: #00DA48;"></i>
            </div>
        </div>
        <div class="col-md-12 my-3 pl-2">
            <span>By confirming you will {{ action?.toLowerCase() }} {{ items.length > 1 ? "the selected offers" : "this offer"}} in {{ contests === "all"? 'all of your contests': `this contest`}}?</span>
            <span v-if="contests !== 'all' && contestName">
                <ul class="ml-0 pl-4">
                    <li><span style="font-weight: 600;">{{ contestName.title }}</span></li>
                </ul>
            </span>
        </div>
        <div class="px-3 mt-4" style="display: flex; flex-direction: row; justify-content: center">
            <base-button class="mr-3" @click="_confirm" slot="footer" type="primary" fill>
                <clip-loader
                    v-if="loading"
                    color="#fff"
                    size="15px"
                    :loading="true"
                ></clip-loader>
                <span v-else>Confirm</span>
            </base-button>
            <base-button class="mr-3" @click="close" slot="footer" type="danger" fill>
                <span>Cancel</span>
            </base-button>
        </div>
    </modal>
</template>
<script>
import Modal from "@/components/Modal";

import { BaseAlert } from "@/components";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

import "./index.css";
import { decodeHtmlEntities } from "../../services/utils";
export default {
    components: {
        BaseAlert,
        Modal,
        ClipLoader,
    },
    data() {
        return {
            theme: localStorage.getItem("theme"),
            loading: false
        };
    },
    computed: {
        textColor() {
            return this.theme == "black" ? "rgba(255, 255, 255, 0.7) !important" : "#28293D";
        },
        subTitleColor() {
            return this.theme == "black" ? "rgba(255, 255, 255, 0.8)" : "#222a42";
        },
        action() {
            // console.log('-----this.value', this.value);
            if (this.actionName === "active") {
                return this.value ? "Enable" : "Disable"
            } else {
                return this.value ? "Pin" : "Unpin"
            }
        }
    },
    props: {
        items: Array,
        value: Boolean,
        show: {
            type: Boolean,
            default: false,
        },
        close: Function,
        actionName: String,
        confirm: Function,
        contests: String,
        contestName: String | Object
    },
    methods: {
        decodeHtml(value) {
            return decodeHtmlEntities(value)
        },
        async _confirm() {
            this.loading = true;
            await this.confirm(this.items, !this.value)
            this.loading = false;
            this.close();
        }
    }
};
</script>
<style></style>

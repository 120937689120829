<template>
    <div class="row justify-content-center">
        <div class="col-12">
            <card class="">
                <div class="row mx-0">
                    <ul class="nav nav-pills">
                        <li class="nav-item">
                            <a
                                slot="header"
                                class="nav-link title"
                                :class="{ 'sub-tab-active': tab == 'videos' }"
                                @click="setTab('videos')"
                                aria-current="page"
                                style="cursor: pointer"
                                >Videos</a
                            >
                        </li>
                        <li class="nav-item">
                            <a
                                slot="header"
                                class="nav-link title"
                                :class="{ 'sub-tab-active': tab == 'articles' }"
                                @click="setTab('articles')"
                                style="cursor: pointer"
                            >
                                Articles</a
                            >
                        </li>
                    </ul>
                </div>

            <player :category="selectedCategory" v-if="selectedCategory && tab !== 'articles'"></player>
            <article-component
                :category="selectedCategory"
                v-if="selectedCategory && tab !== 'videos'"
            ></article-component>
            <div class="col-12" v-for="category in categories">
                <div class="my-4">
                    <h3 style="font-weight: 600">{{ category }}</h3>

                    <VueSlickCarousel :arrows="true" :dots="true" v-bind="settings">
                        <div class="px-2" v-for="item in categoryItems(category)">
                            <div class="item-box" @click="selectCategory(item)">
                                <div class="item-image">
                                    <img src="https://i.ytimg.com/vi_webp/15_DCzw_vHU/maxresdefault.webp" />
                                    <svg
                                        class="item-play-button"
                                        height="100%"
                                        version="1.1"
                                        viewBox="0 0 68 48"
                                        width="100%"
                                    >
                                        <path
                                            class="ytp-large-play-button-bg"
                                            d="M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z"
                                            fill="#f00"
                                        ></path>
                                        <path d="M 45,24 27,14 27,34" fill="#fff"></path>
                                    </svg>
                                </div>

                                <div class="row justify-content-between mx-0 px-1 my-2 align-items-center">
                                    <div class="col-md-9">
                                        <h4 class="mb-0">{{ item.title }}</h4>
                                    </div>
                                    <div class="col-md-3 row justify-content-end align-items-center">
                                        <h4 style="font-size: 15px; margin-right: -5px" class="mb-0">
                                            {{ item[tab].length }}
                                        </h4>
                                        <svg
                                            v-if="tab === 'videos'"
                                            style="margin-bottom: 5px"
                                            height="35px"
                                            version="1.1"
                                            viewBox="0 0 36 36"
                                            width="35px"
                                            class="playlist-icon"
                                        >
                                            <use class="ytp-svg-shadow" xlink:href="#ytp-id-25"></use>
                                            <path
                                                d="m 22.53,21.42 0,6.85 5.66,-3.42 -5.66,-3.42 0,0 z m -11.33,0 9.06,0 0,2.28 -9.06,0 0,-2.28 0,0 z m 0,-9.14 13.6,0 0,2.28 -13.6,0 0,-2.28 0,0 z m 0,4.57 13.6,0 0,2.28 -13.6,0 0,-2.28 0,0 z"
                                                :fill="theme === 'black' ? 'rgba(255, 255, 255, 0.8)' : '#222a42'"
                                                id="ytp-id-25"
                                            ></path>
                                        </svg>
                                        <i
                                            v-if="tab === 'articles'"
                                            style="font-size: 25px"
                                            :style="{
                                                color: theme === 'black' ? 'rgba(255, 255, 255, 0.8)' : '#222a42',
                                            }"
                                            class="tim-icons icon-paper article-icon my-2 mr-1"
                                        >
                                        </i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </VueSlickCarousel>
                </div>

                <!-- <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/videoseries?list=PL590L5WQmH8eYTXiTTjzo4YnK7vkCqZTI"
                    frameborder="0"
                    allowfullscreen
                ></iframe> -->
            </div>
        </card>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import "./Help/index.css";
import Player from "./Help/Player.vue";
import ArticleComponent from "./Help/Article.vue";

const isMobile = window.innerWidth <= 777;

export default {
    components: {
        ClipLoader,
        VueSlickCarousel,
        Player,
        ArticleComponent,
    },
    data() {
        return {
            tab: "videos",
            theme: localStorage.getItem("theme"),
            settings: {
                arrows: true,
                dots: true,
                infinite: true,
                slidesToShow: isMobile ? 1 : 3,
                slidesToScroll: 1,
                autoplay: true,
                speed: 500,
                autoplaySpeed: 3000,
                cssEase: "linear",
            },
            selectedCategory: null,
            loading: false,
        };
    },
    mounted() {
        this.getHelp();
    },
    computed: {
        ...mapGetters(["help"]),
        categories() {
            // console.log("---this.help", this.help);
            if (!this.help?.videos) return [];
            return this.tab === "videos" ? Object.keys(this.help?.videos) : Object.keys(this.help?.articles);
        },
    },
    methods: {
        async getHelp() {
            this.loading = true;
            await this.$store.dispatch("getHelp");
            this.loading = true;
        },
        selectCategory(item) {
            // console.log("---item", item);
            if (item.articles.length < 1 && item.videos.length < 1) return;
            this.selectedCategory = item;
            window.scrollTo({
                top: 0,
                behavior: 'smooth', // Smooth scroll animation
            });
        },
        setTab(item) {
            this.selectedCategory = null;
            this.tab = item;
        },
        categoryItems(cat) {
            // console.log("---this.help", this.help, this.tab, this.help[this.tab]);
            return this.help[this.tab]?.[cat] || [];
        },
    },
    watch: {},
};
</script>
<style>
.slick-prev:before, .slick-next:before {
    color: black !important;

}
</style>

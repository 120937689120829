<template>
    <div class="row justify-content-center">
        <div class="col-12">
            <div class="card">
                <div class="card-header">
                    <h4 class="mb-3 card-title text-bold">Themes</h4>
                    <h5>here are some premade contest for you to use</h5>
                </div>
            </div>

            <div
                v-if="loading"
                style="height: 80px; width: 100%"
                class="row justify-content-center align-items-center"
            >
                <clip-loader color="#C153EC" size="40px" :loading="loading"></clip-loader>
            </div>

            <div class="row mx-0" v-else>
                <div class="col-6 col-md-2" style="background: transparent; align-items: stretch; display: flex" v-for="theme in themes">
                    <div class="theme-card" :style="{background: siteTheme == 'black' ? '#26293C' : 'white'}">
                        <img 
                            :src="theme.theme?.media"
                        />
                        <div class="theme-card-body px-2">
                            <span class="text-bold " style="font-size: 14px;"  :style="{color: siteTheme === 'black' ? 'white' : 'undefined'}" >{{  theme.title }}</span>
                            <!-- <p class="mt-1" style="color: black; font-size: 12px;" :style="{color: siteTheme === 'black' ? 'gray' : 'black'}" >{{ theme.description }}</p> -->
                        </div>
                        <div class="">

                            <base-button style="width: 90%; font-size: 13px;" class="" size="sm" :type="'primary'" @click="useTheme(theme)" fill>Edit theme and use</base-button>
                        </div>

                    </div>

                </div>
            </div>

        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";

export default {
    components: {
        ClipLoader
    },
    data() {
        return {
            siteTheme: localStorage.getItem('theme'),
            loading: true
        };
    },
    mounted() {
        this.getThemes();
    },
    computed: {
        ...mapGetters(["themes"]),
    },
    methods: {
        async getThemes() {
            this.loading = true;
            await this.$store.dispatch("getThemes");
            this.loading = false;
        },
        useTheme(theme) {
            this.$router.push('/contest/theme/'+theme._id)
        }
    },
    watch: {},
};
</script>
<style>
.theme-card {
    /* background-color: white;  */
    /* padding-bottom: 10px;
    text-align: center;
    border-radius: 5px;
    margin-bottom: 30px; */
    display: flex;           /* Make it a flex container */
    flex-direction: column;  /* Stack children vertically */
    padding-bottom: 10px;
    text-align: center;
    border-radius: 5px;
    margin-bottom: 30px;
    width: 100%; 
}

@media(max-width: 500px) {
    .theme-card {
        margin-bottom: 10px;
    }

}

.theme-card img {
    height: 110px;
    /* object-fit: cover; */
    width: 100%;
    border-radius: 0px;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;
    /* box-shadow: 10px; */
}

.theme-card-body {
    flex: 1; /* This allows the body to grow, pushing the button to the bottom */

    flex-grow: 1;            /* Allow it to consume all available vertical space */
    padding-top: 10px;
    padding-bottom: 10px;
}

.col-3,
.col-md-2 {
    padding-right: 5px !important;
    padding-left: 5px !important;
}
</style>

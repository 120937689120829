<template>
    <div>
        <a
            class="nav-link title action-button text-center"
            @click="switchShowActions(true)"
            :style="{ 'background-color': entry.style?.color, 'border-color': entry.style.color }"
            style="width: 130px; cursor: pointer; color: white; position: relative;"
        >
            <i :class="entry.style?.icon" style="position: absolute; left: 8px; top: 11px; bottom:10px;"></i>
            {{ entry.title }}
        </a>
        <div
            v-if="showActions"
            class="actions-dropdown py-1 mt-1 px-2"
            style="border: solid; border-width: 0.5px; position: absolute"
        >
            <div class="p-2 row">
                <div
                    class="col-md-12 py-2"
                    v-for="action in entry.actions"
                    :class="{ 'hover-black': theme === 'black', 'hover-white': theme !== 'black' }"
                >
                    <span style="font-size: 13px" @click="add(action)">{{ action.title }}</span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import BaseButton from "../BaseButton.vue";

import "./index.css";
export default {
    name: "action-button",
    components: {
        BaseButton,
    },
    props: {
        entry: Object,
        addAction: Function,
        closeActionsButton: Function,
    },
    data() {
        return {
            showActions: false,
            theme: localStorage.getItem("theme"),
        };
    },
    methods: {
        switchShowActions(status) {
            // console.log("--this.show", this.showActions);
            this.showActions = !this.showActions;
            this.closeActionsButton(this.entry._id)
        },
        closeShowActions() {
            this.showActions = false

        },
        add(action) {
            let actionObj = {...action, entry: {style: this.entry.style}}
            this.switchShowActions();
            this.addAction(actionObj);
        },
    },
};
</script>
<style></style>

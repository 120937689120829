<template>
    <card type="offerwall">
        <h5 slot="header" class="title">User Details</h5>
        <div class="">
            <label class="control-label">User ID:</label>
            <span class="ml-2">{{ user?.user_id }}</span>
        </div>
        <div class="mt-3">
            <label class="control-label">Email:</label>
            <span class="ml-2">{{ user?.email || 'none' }}</span>
        </div>
        <!-- <div class="mt-3">
            <label class="control-label">Facebook profile:</label>
            <a v-if="user?.facebook?.id" target="_blank" :href="`https://www.facebook.com/profile.php?id=123456789${user?.facebook?.id}`" class="ml-2">Visit Profile</a>
            <span v-else class="ml-2">none</span>

        </div> -->
        <div class="mt-3">
            <label class="control-label">Country:</label>
            <span class="ml-2">{{ user?.country }}</span>
        </div>
        <div class="mt-3">
            <label class="control-label">Device:</label>
            <span class="ml-2">{{ user?.device }}</span>
        </div>
        <div class="mt-3">
            <label class="control-label">Status:</label>
            <span class="ml-2">{{ user?.blocked ? "Blocked" : "Active" }}</span>
        </div>
        <div class="mt-3">
            <label class="control-label">Entries:</label>
            <span class="ml-2">{{ user?.entries || 0 }}</span>
        </div>
        <div class="mt-3">
            <label class="control-label">Tickets earned:</label>
            <span class="ml-2">{{ user?.total_amount || 0 }}</span>
        </div>
        <div class="mt-3">
            <label class="control-label">Date joined:</label>
            <span class="ml-2">{{ dateJoined }}</span>
        </div>
        <div class="mt-3">
            <label class="control-label">Last visit:</label>
            <span class="ml-2">{{ lastVisit }}</span>
        </div>
    </card>
</template>
<script>
import moment from "moment";

export default {
    updated() {
        // console.log('-----', this.offerwall);
    },
    created() {
        // console.log('----- offerwall', this.offerwall);
    },
    computed: {
        lastVisit() {
            return this.user?.last_visit ? moment(this.user.last_visit).format("YYYY-MM-DD HH:mm:ss") : "";
        },
        dateJoined() {
            return this.user?.createdAt ? moment(this.user.createdAt).format("YYYY-MM-DD HH:mm:ss") : "";
        },
    },
    props: {
        user: {
            type: Object,
        },
    },
    methods: {},
};
</script>
<style></style>

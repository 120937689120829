<template>
    <div class="contact-us full-screen">
        <nav
            class="navbar navbar-ct-default"
            role="navigation-demo"
            :style="{ 'background-color': theme === 'black' ? 'unset' : '#F5F6FA' }"
            style="height: 10vh;"
        >
            <div class="container" style="width: 100%">
                <!-- Brand and toggle get grouped for better mobile display -->
                <div class="navbar-header">
                    <a :href="homeLink" alt="adviral.io"> 
                        <img 
                            src="/img/logo-adviral.png"
                            style="width: 100px"
                        />
                    </a>
                </div>

                <!-- /.navbar-collapse -->
            </div>
            <!-- /.container-->
        </nav>
        <div class="">
            <div class="">
                <div class="container">
                    <div class="row justify-content-center align-items-center" style="height: 80vh">
                        <div class="col-md-12 text-center">
                            <h2 class="title text-primary">Not Found</h2>
                            <h2 class="title" :style="{ color: theme === 'black' ? 'unset' : 'black !important' }">
                                Oops! It seems that this page does not exist.
                            </h2>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- <footer class="footer-demo">
            <div class="container">
                <nav class="pull-left">
                    <ul>
                        <li>
                            <router-link :to="{ path: '/' }">Home</router-link>
                        </li>
                        <li>
                            <router-link :to="{ path: '/register' }">Register</router-link>
                        </li>
                    </ul>
                </nav>
                <div class="copyright pull-right">
                    &copy; 2018, made with
                    <i class="fa fa-heart heart"></i> by Black admin
                </div>
            </div>
        </footer> -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            theme: localStorage.getItem("theme"),
        };
    },
    computed: {
        homeLink() {
            return process.env.VUE_APP_IS_TOOL === 'true' ? 'https://adviral.io' : '/' 
        }
    }
};
</script>

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row mt-4"},[(_vm.selectedArticle)?_c('div',{staticClass:"col-12 col-md-8"},[_c('h2',[_vm._v(_vm._s(_vm.category.title)+" - "+_vm._s(_vm.selectedArticle.title))]),_c('img',{staticStyle:{"width":"100%"},attrs:{"src":_vm.selectedArticle.image}}),_c('div',{staticClass:"my-4 html-section",domProps:{"innerHTML":_vm._s(_vm.selectedArticle.content)}})]):_vm._e(),_c('div',{staticClass:"col-12 col-md-4",staticStyle:{"height":"500px","overflow-y":"scroll"}},_vm._l((_vm.category.articles),function(video,index){return _c('div',{staticClass:"row mb-2 next-video-card mx-0 px-0",staticStyle:{"cursor":"pointer","height":"auto"},style:({ 'border-color': video?._id == _vm.selectedArticle?._id ? '#00DA48' : 'unset' }),on:{"click":function($event){return _vm.selectArticle(video)}}},[_vm._m(0,true),_c('div',{staticClass:"col-md-8 py-1",staticStyle:{"display":"flex","flex-direction":"column","justify-content":"space-between"},style:({ opacity: video?._id == _vm.selectedArticle?._id ? 0.7 : 1 })},[_c('div',{staticStyle:{"font-weight":"600","font-size":"18px"},style:({
                        color:
                            video?._id == _vm.selectedArticle?._id
                                ? '#00DA48'
                                : _vm.theme == 'black'
                                ? 'rgba(255, 255, 255, 0.8)'
                                : 'unset',
                    })},[_vm._v(" "+_vm._s(video.title)+" ")])])])}),0)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-4 px-0",staticStyle:{"max-height":"100px"}},[_c('img',{staticStyle:{"height":"100%"},attrs:{"src":"https://i.ytimg.com/vi_webp/15_DCzw_vHU/maxresdefault.webp"}})])
}]

export { render, staticRenderFns }
<!-- eslint-disable vue/no-mutating-props -->
<template>
    <div class="row mt-4">
        <div class="col-12 col-md-8" v-if="selectedVideo">
            <h2>{{ category.title }} - {{ selectedVideo.title }}</h2>

            <iframe
                class="iframe"
                type="text/html"
                width="100%"
                :src="'https://www.youtube.com/embed/' + selectedVideo.video_id"
                allowfullscreen
            >
            </iframe>

            <div
                v-if="selectedVideo.description"
                class="mt-4 px-2 html-section"
                v-html="selectedVideo.description"
            ></div>
        </div>

        <div class="col-12 col-md-4 hide-video-mobile" style="height: 500px; overflow-y: scroll">
            <div
                class="row mb-2 next-video-card mx-0 px-0"
                style="cursor: pointer;     min-height: 70px;"
                :style="{ 'border-color': video._id == selectedVideo._id ? '#00DA48' : 'unset' }"
                v-for="(video, index) in category.videos"
                @click="selectVideo(video)"
                :class="{ 'hide-mobile': video._id === selectedVideo._id }"
            >
                <div class="col-md-1 justify-content-center align-items-center row next-video-card-index">
                    <!-- <i
                        v-if="video._id == selectedVideo._id"
                        style="color: #00DA48"
                        class="tim-icons icon-triangle-right-17"
                    ></i> -->
                    <span class="hide-mobile">{{ index + 1 }}</span>
                </div>
                <div class="col-4 col-md-3 px-0" style="max-height: 100px;">
                    <img src="https://i.ytimg.com/vi_webp/15_DCzw_vHU/maxresdefault.webp" style="height: 100%" />
                </div>
                <div
                    class="col-8 col-md-8 py-1"
                    style="display: flex; flex-direction: column; justify-content: center;"
                    :style="{ opacity: video._id == selectedVideo._id ? 0.7 : 1 }"
                >
                    <div
                        style="font-weight: 600; font-size: 15px"
                        :style="{ color: video._id == selectedVideo._id ? '#00DA48' : 'unset' }"
                    >
                        {{ video.title }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import "./player.css";

export default {
    components: {},
    created() {},
    data() {
        return {
            selectedVideo: null,
        };
    },
    props: {
        category: Object,
    },
    mounted() {
        this.selectedVideo = this.category.videos[0];
    },
    methods: {
        selectVideo(item) {
            this.selectedVideo = item;
        },
    },
    watch: {
        category(val) {
            this.selectedVideo = val.videos[0];
        },
    },
};
</script>
<style></style>

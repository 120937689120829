<template>
    <div 
        id="contest-container"
        class="row justify-content-center mx-0"
        >
        <div style="width: 500px">
            <contest-card :loading="loading" :loadingOffers="loadingOffers" :contest="publicContest" :participant="participant"
                :demoOffers="publicContestOffers" :refreshContest="getContest" :refreshOffers="getContestOffers"></contest-card>
        </div>
    </div>
</template>
<script>
import { FadeTransition } from "vue2-transitions";
import ContestCard from "./Contest/ContestCard";
import { mapGetters } from "vuex";

export default {
    name: "base-alert",
    components: {
        FadeTransition,
        ContestCard,
    },
    props: {
        link: {
            type: String,
        },
        device: String,
        demoOffers: Array,
        initialized: Boolean,
    },
    data() {
        return {
            visible: true,
            loading: true,
            loadingOffers: true,
            page: 1,
            enableRefresh: true,
            isInitialized: false
        };
    },
    created() {
        let ckeditor = document.createElement("script");
        ckeditor.setAttribute("src", "https://platform.twitter.com/widgets.js");
        document.head.appendChild(ckeditor);
    },
    mounted() {
        this.getContest();
        localStorage.setItem("theme", "white");

    },
    computed: {
        ...mapGetters([
            "publicContest",
            "participant",
            "errors",
            "successMessages",
            "loads",
            "contestEntries",
            "offersDemos",
            "publicContestOffers",
        ]),
    },
    methods: {
        dismissAlert() {
            this.visible = false;
        },
        getContest: async function (sort, sortBy, reset = false) {
            // console.log('---here');
            this.loading = true;
            await this.$store.dispatch(
                "getPublicContest",
                { id: this.$route.params.id, page: this.page, sort, sortBy, user_id:  this.$route.query.user_id},
                this.$router,
            );
            // console.log("---after", this.publicContest);
            this.loading = false;
            const url = window.location.protocol + "//" + window.location.host + window.location.pathname;
            // console.log('---url', url);
            window.history.pushState({path: url}, '', url);
            if (!this.publicContest) {
                this.$router.replace("404");
            }
            this.setBackgroundImage()
            if (reset) {
                this.isInitialized = false;
            }
            this.getContestOffers("sort", sort, sortBy)
            
        },
        getContestOffers: async function(type, sort, sortBy) {
            // console.log('----enableRefresh', this.enableRefresh, {type, sort, sortBy});
            // let page = this.page;
            let override = false;
            if (type === "sort") {
                this.$store.commit("clearPublicContestOffers")
                this.page = 1;
                this.enableRefresh = true
                override = true
            } else {
                if (!this.enableRefresh) {
                    return;
                }
                this.page += 1;
            }

            if (!this.isInitialized && type === "scroll") {
                return;
            }

            if (!this.isInitialized) {
                this.loadingOffers = true;
            }
            const result = await this.$store.dispatch(
                "getPublicContestOffers",
                { id: this.$route.params.id, page: this.page, sort, sortBy, user_id: this.$route.query.user_id, override},
            );
            // console.log('----this.publicContestOffers', this.publicContestOffers.length);
            if (result.success && result.total === 0) {
                this.enableRefresh = false;
            }
            this.loadingOffers = false;
            this.isInitialized = true
        },
        setBackgroundImage() {
            const background_image = this.publicContest?.background_image;
            setTimeout(function () {
                // console.log('---background image', background_image);
                if (background_image) {
                    // console.log('---background image inside', background_image);

                    // ele.style.backgroundImage = `url("${background_image}")`
                    const ele = document.getElementById('contest-container');

                    ele.style.cssText = `background: url("${background_image}") !important`;

                    // $('.contest-container ').css('background-image', );
                } else {
                    // console.log('---background image outside', background_image);

                    // const ele = document.getElementById('contest-container');
                    // ele.style.cssText = `background: linear-gradient(282deg, #BCFF2F 0%, #455B97 70.91%, #A61212 100%); height: 100vh; max-height: 100vh`;

                }
            },100)
        }
    },
    watch: {
        publicContest(val, oldValue) {
            if (this.page > 1 && val.offers?.length === oldValue.offers?.length) {
                this.enableRefresh = false;
            }
        },
    },
};
</script>

<style scope>
#contest-container {
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    background-position: top center;
    height: 100vh;
    max-height: 100vh;
    background: linear-gradient(282deg, #BCFF2F 0%, #455B97 70.91%, #A61212 100%);
}
</style>
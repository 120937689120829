<template>
    <div class="row">
        <view-offer 
            v-if="selectedOffer"
            :show="showViewOfferModal"
            :model="selectedOffer"
            :close="closeViewOfferModel"
            :contests="contests"
            :selected-contest="contest"
        ></view-offer>
        <confirm-update-offer 
            :show="confirmUpdatePopupShowed"
            :items="offersToUpdate"
            :close="closeConfirmUpdatePopup"
            :actionName="actionToUpdate"
            :value="valueToUpdate"
            :contests="contest"
            :confirm="actionToUpdate == 'active' ? updateOffer : pinOffer"
            :contestName="contest === 'all' ? 'all' : contests.find(x => x._id == contest)"
        >
        </confirm-update-offer>
        <div class="col-12">
            <card :title="table1.title">
                <div class="row justify-content-start px-2">
                    <div class="col-12 justify-content-start filters">
                        <base-select
                            :source="'offer'"
                            :onChange="(val) => onFilterChange('country', val)"
                            :value="country"
                            :label="countryFilter.label"
                            class="pr-2 col-md-3 w-full"
                            :options="countryFilter.options"
                            :default="country"
                        ></base-select>
                        <base-select
                            :source="'offer'"
                            :onChange="(val) => onFilterChange('device', val)"
                            :value="device"
                            :label="deviceFilter.label"
                            class="pr-2 col-md-3 w-full"
                            :options="deviceFilter.options"
                            :default="device"
                        ></base-select>
                        <base-select
                            :source="'offer'"
                            :onChange="(val) => onFilterChange('category', val)"
                            :value="category"
                            :label="categoryFilter.label"
                            class="pr-2 col-md-3 w-full"
                            :options="categoryFilter.options"
                            :default="category"
                        ></base-select>
                        <base-select
                            :source="'offer'"
                            :onChange="(val) => onFilterChange('contest', val)"
                            :value="contest"
                            :label="contestFilter.label"
                            class="pr-2 col-md-3 w-full"
                            :options="contestFilter.options"
                            :default="contest"
                        ></base-select>
                    </div>
                </div>
                <div class="row justify-content-between mt-4 px-4">
                    <div class="row col-7 col-md-6 justify-content-start">
                        <base-input
                            :onChange="(val) => onSearchChange(val)"
                            :model="search"
                            :placeholder="'Search ...'"
                            class="mr-2 searchFilter"
                            :options="countryFilter.options"
                            :value="search"
                        ></base-input>
                    </div>
                    <div class="row col-5 col-md-6 justify-content-end">
                        <base-button @click="clearFilters" size="sm" :type="'primary'" fill>Clear filters</base-button>
                    </div>
                </div>

                <div class="table-responsive">
                    <new-base-table
                        :data="table1.data" 
                        :columns="table1.columns"
                        thead-classes="text-primary"
                        :loading="loading"
                        :totalItems="totalOffers"
                        :onPageChange="getOffers"
                        :onSelect="onSelect"

                    >
                        <template v-slot:globalControl="{items}">
                            <base-button
                                @click="showConfirmUpdatePopup(items, !isMajoritySelectedActive(items), 'active')"
                                size="sm"
                                :type="isMajoritySelectedActive(items) ? 'danger' : 'primary'"
                                fill
                                >{{ isMajoritySelectedActive(items) ? "Disable All" : "Enable all" }}</base-button
                            >
                        </template>
                        <template v-slot:control="{item, index}">
                            <base-button
                                style="width: 83px"
                                @click="openViewOfferModel(item._id)"
                                size="sm"
                                type="info"
                                fill
                            >
                                <span style="margin-left: 0px !important"> View</span>
                            </base-button>
                            <base-button
                                style="width: 83px"
                                @click="showConfirmUpdatePopup([item._id], !item.active, 'active')"
                                size="sm"
                                :type="item.active ? 'danger' : 'secondary'"
                                fill
                            >
                                <clip-loader
                                    v-if="loadingItem?.['active'] == item._id"
                                    color="#fff"
                                    size="15px"
                                    :loading="true"
                                ></clip-loader>

                                <span style="margin-left: 0px !important" v-else>{{
                                    item.active ? "Disable" : "Enable"
                                }}</span>
                            </base-button>

                            <base-button
                                style="width: 83px"
                                @click="showConfirmUpdatePopup([item._id], !item.pinned, 'pin')"
                                size="sm"
                                :type="item.pinned ? 'primary' : 'secondary'"
                                fill
                            >
                                <clip-loader
                                    v-if="loadingItem?.['pin'] == item._id"
                                    color="#fff"
                                    size="15px"
                                    :loading="true"
                                ></clip-loader>

                                <span style="margin-left: 0px !important" v-else>{{
                                    item.pinned ? "Unpin" : "Pin"
                                }}</span>
                            </base-button>
                        </template>
                    </new-base-table>

                </div>
            </card>
        </div>
    </div>
</template>
<script>
import { OfferTable, NewBaseTable } from "@/components";
import { BaseSelect } from "@/components";
import { BaseInput } from "@/components";
import { Pagination } from "@/components";
import { mapGetters } from "vuex";
import Countries from "../assets/data/countries.json";
import ClipLoader from "vue-spinner/src/ClipLoader.vue";
import ViewOffer from "./Offer/ViewOffer.vue";
import ConfirmUpdateOffer from "./Offer/ConfirmUpdateOffer.vue";
import { decodeHtmlEntities } from "../services/utils";

const tableColumns = [
  { key: "select", name: "select" },
  { key: "offer_id", name: "offer_id" },
  { key: "logo", name: "logo", type: "image", filter: false },
  { key: "name", name: "name", type: 'html', minWidth: "120px",
    renderer: ({name}) => `<span style="margin-left: 0px !important; max-width: 230px">${decodeHtmlEntities(name)}</span>`  },
  { key: "category", name: "category", sortBy: "category" },
  {
    key: "countries",
    name: "countries",
    sortBy: "country",
    renderer: ({ countries }) => {
      if (countries.length > 10) {
        const shortCountries = [...countries].slice(0, 10);
        return `${shortCountries.toString()}...`;
      }

      return countries.toString();
    },
  },
  {
    key: "devices",
    name: "devices",
    sortBy: "device",
    type: "html",
    renderer: ({ devices }) => {
        // console.log('---devices', devices);
        // return ''
        return devices.map(device => {
            if (device === "android") {
                return `
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="#C6C5CA"
                        class="bi bi-android2 mr-1"
                        viewBox="0 0 16 16"
                    >
                        <path
                            d="m10.213 1.471.691-1.26c.046-.083.03-.147-.048-.192-.085-.038-.15-.019-.195.058l-.7 1.27A4.832 4.832 0 0 0 8.005.941c-.688 0-1.34.135-1.956.404l-.7-1.27C5.303 0 5.239-.018 5.154.02c-.078.046-.094.11-.049.193l.691 1.259a4.25 4.25 0 0 0-1.673 1.476A3.697 3.697 0 0 0 3.5 5.02h9c0-.75-.208-1.44-.623-2.072a4.266 4.266 0 0 0-1.664-1.476ZM6.22 3.303a.367.367 0 0 1-.267.11.35.35 0 0 1-.263-.11.366.366 0 0 1-.107-.264.37.37 0 0 1 .107-.265.351.351 0 0 1 .263-.11c.103 0 .193.037.267.11a.36.36 0 0 1 .112.265.36.36 0 0 1-.112.264Zm4.101 0a.351.351 0 0 1-.262.11.366.366 0 0 1-.268-.11.358.358 0 0 1-.112-.264c0-.103.037-.191.112-.265a.367.367 0 0 1 .268-.11c.104 0 .19.037.262.11a.367.367 0 0 1 .107.265c0 .102-.035.19-.107.264ZM3.5 11.77c0 .294.104.544.311.75.208.204.46.307.76.307h.758l.01 2.182c0 .276.097.51.292.703a.961.961 0 0 0 .7.288.973.973 0 0 0 .71-.288.95.95 0 0 0 .292-.703v-2.182h1.343v2.182c0 .276.097.51.292.703a.972.972 0 0 0 .71.288.973.973 0 0 0 .71-.288.95.95 0 0 0 .292-.703v-2.182h.76c.291 0 .54-.103.749-.308.207-.205.311-.455.311-.75V5.365h-9v6.404Zm10.495-6.587a.983.983 0 0 0-.702.278.91.91 0 0 0-.293.685v4.063c0 .271.098.501.293.69a.97.97 0 0 0 .702.284c.28 0 .517-.095.712-.284a.924.924 0 0 0 .293-.69V6.146a.91.91 0 0 0-.293-.685.995.995 0 0 0-.712-.278Zm-12.702.283a.985.985 0 0 1 .712-.283c.273 0 .507.094.702.283a.913.913 0 0 1 .293.68v4.063a.932.932 0 0 1-.288.69.97.97 0 0 1-.707.284.986.986 0 0 1-.712-.284.924.924 0 0 1-.293-.69V6.146c0-.264.098-.491.293-.68Z"
                        />
                    </svg>
                `
            }
            if (device === "ios") {
                return `
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="#C6C5CA"
                        class="bi bi-apple"
                        viewBox="0 0 16 16"
                    >
                        <path
                            d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43Zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282Z"
                        />
                        <path
                            d="M11.182.008C11.148-.03 9.923.023 8.857 1.18c-1.066 1.156-.902 2.482-.878 2.516.024.034 1.52.087 2.475-1.258.955-1.345.762-2.391.728-2.43Zm3.314 11.733c-.048-.096-2.325-1.234-2.113-3.422.212-2.189 1.675-2.789 1.698-2.854.023-.065-.597-.79-1.254-1.157a3.692 3.692 0 0 0-1.563-.434c-.108-.003-.483-.095-1.254.116-.508.139-1.653.589-1.968.607-.316.018-1.256-.522-2.267-.665-.647-.125-1.333.131-1.824.328-.49.196-1.422.754-2.074 2.237-.652 1.482-.311 3.83-.067 4.56.244.729.625 1.924 1.273 2.796.576.984 1.34 1.667 1.659 1.899.319.232 1.219.386 1.843.067.502-.308 1.408-.485 1.766-.472.357.013 1.061.154 1.782.539.571.197 1.111.115 1.652-.105.541-.221 1.324-1.059 2.238-2.758.347-.79.505-1.217.473-1.282Z"
                        />
                    </svg>
                `
            }
        }).join(" ")
    },
  },
  { key: "payout", name: "payout", renderer: ({payout}) => `${payout} $` },
  { key: "status", name: "status", sortBy: "active", renderer: ({active}) => active ? "Enabled" : "Disabled"},
  { key: "pinned", name: "pinned", renderer: ({pinned}) => pinned ? "Yes" : "No"},
  { key: "control", name: "control", filter: false },
];

// const tableColumns = [
//     "select",
//     "Offer_id",
//     "Icon",
//     "Name",
//     "Category",
//     "Countries",
//     "Devices",
//     "Payout",
//     "Status",
//     "Pinned",
//     "control",
// ];
// const tableSortedColumns = [
//     "Offer_id",
//     "Name",
//     "Category",
//     "Countries",
//     "Payout",
//     "Status",
//     "Pinned",
// ];

export default {
    components: {
        OfferTable,
        BaseSelect,
        BaseInput,
        Pagination,
        ClipLoader,
        ViewOffer,
        NewBaseTable,
        ConfirmUpdateOffer
    },
    async mounted() {
        let countries = Countries.map((x) => {
            return {
                title: x.name,
                value: x.code,
            };
        });
        this.countryFilter.options = [...this.countryFilter.options, ...countries];
        this.init()
        // console.log('-----finished');
    },
    data() {
        return {
            table1: {
                title: "Offers",
                columns: [...tableColumns],
                // sortedColumns: [...tableSortedColumns],
                data: [],
            },
            countryFilter: {
                label: "Country",
                name: "country",
                options: [{ title: "ALL", value: "all" }],
            },
            deviceFilter: {
                label: "Device",
                name: "device",
                options: [
                    { title: "ALL", value: "all" },
                    { title: "Android", value: "android" },
                    { title: "Iphone", value: "ios" },
                ],
            },
            contestFilter: {
                label: "Contest",
                name: "contest",
                options: [
                    { title: "All", value: "all" },
                ],
            },
            categoryFilter: {
                label: "Category",
                name: "category",
                options: [
                    { title: "All", value: "all" },
                    { title: "CPE", value: "CPE" },
                    { title: "CPI", value: "CPI" },
                ],
            },
            country: "all",
            device: "all",
            search: null,
            category: "all",
            contest: "all",
            currentPage: null,
            limit: null,
            sort: null,
            sortBy: null,
            selectedItems: [],
            loading: false,
            initialized: false,
            loadingItem: null,
            statusLoadingItem: null,
            showViewOfferModal: true,
            selectedOffer: null,
            clearing: false,
            confirmUpdatePopupShowed: false,
            offersToUpdate: [],
            actionToUpdate: null,
            valueToUpdate: null,
            // pinnedOffers: []
        };
    },
    computed: {
        totalPages() {
            return Math.ceil(this.filteredOffers.length / this.perPage);
        },
        filteredOffers() {
            return this.offers;
        },
        ...mapGetters(["offers", "totalOffers",  "contests"]),
    },
    methods: {
        init: async function() {
            await Promise.all([
                this.getContests(),
                this.getOffers(),
            ])
            // console.log('---after init');
            this.initialized = true
        },
        getContests: async function () {
            await this.$store.dispatch("getContests", {select: "excluded_offers pinned_offers title"});
            this.contestFilter.options = [...this.contestFilter.options, ...this.contests.map(x => ({title: x.title, value: x._id}))]
        },
        countOffers: async function (filters = {}) {
            this.loading = true;
            await this.$store.dispatch("countOffers", filters);
        },
        getOffers: async function (page = 1, limit = 50, sortBy = "createdAt", sort = -1) {
            // console.log('--getOffers');
            this.loading = true;
            this.table1.data = [];
            const filters = {
                country: this.country,
                device: this.device,
                search: this.search,
                category: this.category,
                contest: this.contest
            }
            if (
                JSON.stringify(this.oldFilters) !== JSON.stringify(filters)
            ) {
                await this.countOffers(filters) //TODO: don't always load this
            }
            this.oldFilters = {...filters};

            this.currentPage = page;
            this.limit = limit;
            this.sortBy = sortBy;
            this.sort = sort;
            await this.$store.dispatch("getOffers", {
                // country: this.country, device: this.device
                page: this.currentPage,
                limit: this.limit,
                sortBy: this.sortBy,
                sort: this.sort,
                ...filters
            });
            this.table1.data = [...this.filteredOffers];
            this.loading = false;
        },
        onFilterChange: function (key, val) {
            if (key == "search") return;
            // console.log('---onFilterChange', key, val);
            this[key] = val;
            // console.log('----onFilterChange', key, val, this.clearing);
            if (this.initialized && !this.clearing) {
                this.getOffers();
            }
        },
        showConfirmUpdatePopup(ids, status, action) {
            this.offersToUpdate = ids;
            this.actionToUpdate = action
            this.valueToUpdate = status;
            this.confirmUpdatePopupShowed = true;
        },
        closeConfirmUpdatePopup() {
            this.confirmUpdatePopupShowed = false;
            this.offersToUpdate = [];
            this.actionToUpdate = null
            this.valueToUpdate = null;
        },
        updateOffer: async function (ids, status) {
            if (ids.length == 1) {
                this.loadingItem = { active: ids[0] };
            } else {
                this.loading = true;
            }

            const success = await this.$store.dispatch("updateOffer", {
                active: !status,
                ids,
                contests: this.contest === "all" ? "all" : [this.contest]
            });
            
            await this.$store.dispatch("getContests", {select: "excluded_offers pinned_offers title"});

            if (success) {
                this.notifyVue(true, "Successfully updated")
            }
            this.loading = false;
            this.loadingItem = null;
        },
        pinOffer: async function (ids, status) {
            if (ids.length == 1) {
                this.loadingItem = { pinned: ids[0] };
            } else {
                this.loading = true;
            }
            const success = await this.$store.dispatch("updateOffer", {
                pin: !status,
                ids,
                contests: this.contest === "all" ? "all" : [this.contest]
            });
            await this.$store.dispatch("getContests", {select: "excluded_offers pinned_offers title"});

            if (success) {
                this.notifyVue(true, "Successfully updated")
            }
            this.loadingItem = null;
            this.loading = false;

        },
        onSelect: function (ids, value) {
            // console.log('---this.onSelect', ids, value);
            if (value) {
                this.selectedItems = [...new Set(this.selectedItems.concat(ids))];
            } else {
                this.selectedItems = [];
            }
        },
        clearFilters: async function () {
            // console.log('---clear filters');
            let shouldRefresh = false;
            // console.log('---condition', this.country, this.category !== "all", this.contest !== "all");
            if (this.country !== "all" || this.category !== "all" || this.contest !== "all") {
                shouldRefresh = true
            }
            this.clearing = true;
            this.device = "all";
            this.country = "all";
            this.category = "all";
            this.contest = "all";
            this.search = null
            // console.log('----disable clearing', this.clearing, this.initialized, shouldRefresh);
            if (this.initialized && shouldRefresh) {
                // console.log('---inside');
                await this.getOffers(this.page, this.limit, this.sortBy, this.sort)
            }
            this.clearing = false;
        },
        onSearchChange: function (val) {
            this.search = val;
            this.loading = true;
            this.$store.commit("setOffers", [])
            setTimeout(() => {
                // console.log('---setTimeout', this.search, val);
                if (val === this.search) {
                    this.getOffers(this.page, this.limit, this.sortBy, this.sort)
                }
            }, 1000)
        },
        openViewOfferModel: function (id) {
            // console.log("---this.showViewOfferModal", this.showViewOfferModal);
            this.selectedOffer = this.offers.find((x) => x._id == id);
            // console.log("selectedOffer", this.selectedOffer);
            this.showViewOfferModal = true;
        },
        closeViewOfferModel: function () {
            // console.log("---this.closeViewOfferModel", this.showViewOfferModal);

            this.showViewOfferModal = false;
        },
        isMajoritySelectedActive(selectedItems) {
            // console.log('---isMajoritySelectedActive', selectedItems);
            const item = this.filteredOffers.find((x) => x._id == selectedItems[0]);
            // console.log('---isMajoritySelectedActive', item);
            if (item && item.active) return true;
            return false;
        },
        notifyVue(success, message) {
            this.$notify({
                message: message,
                icon: "tim-icons icon-bell-55",
                horizontalAlign: "center",
                verticalAlign: "top",
                type: success ? "success" : "danger",
                timeout: 5000,
            });
        },
    },
    watch: {
        filteredOffers() {
            this.table1.data = [...this.filteredOffers];
        },
    },
};
</script>
<style></style>
